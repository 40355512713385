<swx-dialog cdkDrag>
    <swx-dialog-header cdkDragHandle>{{title}}</swx-dialog-header>
    <swx-dialog-content>
        <div style="display: flex; width: 100%; height: 100%; flex-direction: column;">
            <form>
                <div class="toolbar">
                    <label>
                        <span class="caption">{{'Notify me of changes by email'|translate}}</span>
                        <input type="checkbox" [checked]="changeAlerts?.length > 0" [disabled]="saving" (click)="toggleChangeAlert()" />
                    </label>
                    <filters name="query.Filters" [(ngModel)]="query.Filters" [columnDefs]="columnDefs"></filters>
                    <button type="button" (click)="grid.refresh()"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
                    <button type="button" (click)="grid.export()"><span class="mdi mdi-download"></span>{{'Export'|translate}}</button>
                </div>
            </form>
            <div style="flex: 1 0 auto; display: flex;">
                <grid #grid gridId="changesDialog" [columnDefs]="columnDefs" [resource]="resource" [headerHeight]="65" [parent]="this" [exportResource]="exportResource" [query]="query"></grid>
            </div>
        </div>
    </swx-dialog-content>
</swx-dialog>
