<swx-dialog cdkDrag>
    <swx-dialog-header cdkDragHandle>
        Diff
    </swx-dialog-header>
    <swx-dialog-content>
        <table class="table">
            <tr>
                <td style="background-color: #ffffff; width: 200px;"></td>
                <th>{{'Before'|translate}}</th>
                <th>{{'After'|translate}}</th>
            </tr>
            @for(property of diff.ChangedProperties; track property) {
                <tr>
                    <th>{{property.Property}}</th>
                    <td style="background-color: #ffecec; max-width: 250px; word-wrap: break-word;">{{property.OldValue}}</td>
                    <td style="background-color: #eaffea; max-width: 250px; word-wrap: break-word;">{{property.NewValue}}</td>
                </tr>
            }
            @for(collection of diff.ChangedCollections; track collection) {
                <tr>
                    <td colspan="3" style="background-color: #ffffff;">&nbsp;</td>
                </tr>
                <tr>
                    <td style="background-color: #ffffff;">&nbsp;</td>
                    <th colspan="2">{{collection.Property}}</th>
                </tr>
                @for(properties of collection.ChangedItems; track properties) {
                    @if(properties.length > 0) {
                        <tr>
                            <td style="background-color: #ffffff;">&nbsp;</td>
                            <th>{{'Before'|translate}}</th>
                            <th>{{'After'|translate}}</th>
                        </tr>
                        @for(property of properties; track property) {
                            <tr>
                                <th>{{property.Property}}</th>
                                <td [ngStyle]="{ 'background-color': property.Modified ? '#ffecec' : '#ffffff', 'max-width': '200px', 'word-wrap': 'break-word'}">{{property.OldValue}}</td>
                                <td [ngStyle]="{ 'background-color': property.Modified ? '#eaffea' : '#ffffff', 'max-width': '200px', 'word-wrap': 'break-word'}">{{property.NewValue}}</td>
                            </tr>
                        }
                    }
                }
                @for(properties of collection.NewItems; track properties) {
                    @if(properties.length > 0) {
                        <tr>
                            <td style="background-color: #ffffff;">&nbsp;</td>
                            <th colspan="2">{{'Added'|translate}}</th>
                        </tr>
                        @for(property of properties; track property) {
                            <tr>
                                <th>{{property.Property}}</th>
                                <td style="background-color: #eaffea; max-width: 200px; word-wrap: break-word;" colspan="2">{{property.NewValue}}</td>
                            </tr>
                        }
                    }
                }
                @for(properties of collection.RemovedItems; track properties) {
                    @if(properties.length) {
                        <tr>
                            <td style="background-color: #ffffff;">&nbsp;</td>
                            <th colspan="2">{{'Removed'|translate}}</th>
                        </tr>
                        @for(property of properties; track property) {
                            <tr>
                                <th>{{property.Property}}</th>
                                <td style="background-color: #ffecec; max-width: 200px; word-wrap: break-word;" colspan="2">{{property.OldValue}}</td>
                            </tr>
                        }
                    }
                }
            }
        </table>
    </swx-dialog-content>
</swx-dialog>
