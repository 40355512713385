import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { AuthenticationService } from "swx.front-end-lib";
import { ApiService } from './Api.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
    constructor(
        private router: Router,
        private authentication: AuthenticationService,
        private api: ApiService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.authentication.getCurrentUser()
            .then(() => true,
                () => {
                    this.api.AuthenticationConfiguration.queryObject().$promise.then(env => {
                        if (env.UseSaml2Authentication) {
                            this.samlLoginRedirect();
                        } else {
                            this.router.navigateByUrl('/login');
                        }
                    });

                    return false;
                });
    }

    samlLoginRedirect() {
        this.api.SamlLoginRequest.queryObject().$promise.then(data => {
            let form = document.createElement("form");
            form.method = "POST";
            form.action = data.SingleSignOnServicePost;   
            form.target = "_top";

            let inputSAMLRequest = document.createElement("input");
            inputSAMLRequest.type = "hidden";
            inputSAMLRequest.name = "SAMLRequest";
            inputSAMLRequest.value = btoa(data.SAMLRequest);
            form.appendChild(inputSAMLRequest);  

            let inputRelayState = document.createElement("input");
            inputRelayState.type = "hidden";
            inputRelayState.name = "RelayState";
            form.appendChild(inputRelayState); 
                    
            document.body.appendChild(form);

            form.submit();
        });
    }

    samlLogoutRedirect() {
        this.api.SamlLogoutRequest.queryObject().$promise.then(data => {
            this.authentication.clearCredentials();

            let form = document.createElement("form");
            form.target = "_top";
            document.body.appendChild(form);

            let inputSAMLRequest = document.createElement("input");
            inputSAMLRequest.type = "hidden";
            inputSAMLRequest.name = "SAMLRequest";
            form.appendChild(inputSAMLRequest);

            if (data.SingleLogoutServicePost) {
                form.method = "POST";
                form.action = data.SingleLogoutServicePost;

                inputSAMLRequest.value = btoa(data.SAMLRequest);

                let inputRelayState = document.createElement("input");
                inputRelayState.type = "hidden";
                inputRelayState.name = "RelayState";
                form.appendChild(inputRelayState);
            } else if (data.SingleLogoutServiceRedirect) {
                form.method = "GET";
                form.action = data.SingleLogoutServiceRedirect;

                inputSAMLRequest.value = data.SAMLRequest;
            }

            form.submit();
        });
    }
}
