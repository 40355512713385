import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { ApiService } from '../shared/Api.service';
import { FileDownloadService, StorageService, GoogleMapsLoaderService, QuerySerializerService, GridComponent, SwxModule, GridModule } from 'swx.front-end-lib';
import { RootScope } from '../shared/RootScope.service';
import moment from 'moment';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

interface Query {
    UserId?: number[];
    FromDate?: string;
    ToDate: string;
    Filters: any[];
};

@Component({
    templateUrl: './LoginHistory.component.html',
    styleUrls: ['./LoginHistory.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        SwxModule,
        FormsModule,
        NgIf,
        NgFor,
        GridModule,
    ],
})
export class LoginHistoryComponent implements OnInit {
    datePickerOptions: DateTimePickerOptions;
    users: Array<{ Id: number, Name: string }> = [];
    query: Query;
    fromDate: Date;
    LOGIN_QUERY_KEY = 'ddmsUserLoginQuery';
    @ViewChild('grid', { static: true }) grid: GridComponent;
    tab: string = 'map';
    @ViewChild('userMap', { static: false }) userMap: ElementRef<HTMLElement>;
    mapLoaded: Promise<any> = null;
    markers = [];
    
    columnDefs = [
	    {
		    headerName: 'Login History',
		    children: [
			    {
				    colId: 'Date',
				    headerName: 'Date',
				    field: 'DateTime',
				    pinned: 'left',
				    width: 100,
				    filterType: 'date',
				    valueFormatter: c => c.value ? moment.utc(c.value).format(this.rootScope.dateFormat) : '',
			    },
			    {
				    colId: 'Time',
				    headerName: 'Time',
				    field: 'DateTime',
				    width: 60,
				    pinned: 'left',
				    filterType: 'date',
				    valueFormatter: c => c.value ? moment.utc(c.value).format(this.rootScope.timeFormat) : '',
				    searchable: false
			    },
			    {
				    colId: 'Name',
				    headerName: 'User',
				    field: 'Name',
				    width: 120
			    },
			    {
				    colId: 'ServiceProviderNames',
				    headerName: 'Service Providers',
				    field: 'ServiceProviderNames',
				    width: 120
			    },
			    {
				    colId: 'AirlineNames',
				    headerName: 'Airlines',
				    field: 'AirlineNames',
				    width: 120
			    },
			    {
				    colId: 'IP',
				    headerName: 'IP',
				    field: 'IP',
				    width: 120
			    },
			    {
				    colId: 'Latitude',
				    headerName: 'Latitude',
				    field: 'Latitude',
				    width: 120,
				    filterType: 'float'
			    },
			    {
				    colId: 'Longitude',
				    headerName: 'Longitude',
				    field: 'Longitude',
				    width: 120,
				    filterType: 'float'
			    }
		    ]
	    }
    ];

    constructor(
        public api: ApiService,
        private fileDownloadService: FileDownloadService,
        private storage: StorageService,
        private rootScope: RootScope,
        private googleMapsLoaderService: GoogleMapsLoaderService,
        private querySerializerService: QuerySerializerService,
    ) { }

    ngOnInit(): void {
        this.datePickerOptions = {
            maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        };

        // "from" date defaults to last 7 days
        this.fromDate = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds());
        this.fromDate.setDate(this.fromDate.getDate() - 7);

        this.api.User.query().$promise.then((users) => {
            this.users = users.map(user => ({
                Id: user.Id,
                Name: user.Name
            }));
        });

        // Read query from storage or reset
        const savedQuery = this.storage.load(this.LOGIN_QUERY_KEY);

        if (savedQuery) {
            this.query = savedQuery;
        } else {
            this.resetQuery();
        }

        this.switchTab(this.tab);
    }

    resetQuery() {
        this.query = {
            UserId: [],
            Filters: [],
            FromDate: this.fromDate.toDateString(),
            ToDate: null
        };
    }

    refresh() {
        this.storage.save(this.LOGIN_QUERY_KEY, this.query);

        if (this.tab === 'map') {
            this.refreshMapData();
        } else {
            this.grid.refresh();
        }
    }


    refreshMapData() {
        this.googleMapsLoaderService.load().then(maps => {
            this.mapLoaded.then(map => {
                this.markers.forEach(marker => marker.setMap(null));
                this.markers.length = 0;

                var queryParams = this.querySerializerService.toHttpParams({
                    ...this.query,
                    PageNumber: 1,
                    PageSize: 100,
                    Filters: (this.query.Filters || []).concat([
                        {
                            Field: 'Latitude',
                            Operator: 'NotEmpty'
                        },
                        {
                            Field: 'Longitude',
                            Operator: 'NotEmpty'
                        },
                    ])
                });

                this.api.UserAuthentication
                    .query(queryParams)
                    .$promise
                    .then(response => {
                        var items = response;
                        this.markers = items.map(item => {
                            var loc = new maps.LatLng(item.Latitude, item.Longitude);

                            var icon = new maps.MarkerImage("//maps.google.com/mapfiles/marker.png", new maps.Size(21, 34), new maps.Point(0, 0), new maps.Point(10, 34));

                            return new maps.Marker({
                                map: map,
                                position: loc,
                                label: "<center>" + item.Name + "<br />" + moment.utc(item.Date).format(this.rootScope.dateTimeFormat) + "</center>",
                                icon: icon
                            });
                        });
                    });
            });
        });
    }

    switchTab(tab: string) {
        this.tab = tab;
        
        if (tab === 'map') {
            if (this.mapLoaded !== null) return;

            this.mapLoaded = new Promise((resolve, reject) => {
	            this.googleMapsLoaderService.load().then(maps => {
		            var mapElement = this.userMap.nativeElement;

		            resolve(new maps.Map(mapElement,
			            {
				            zoom: 3,
				            center: new maps.LatLng(45.5017, -73.5673),
				            mapTypeId: maps.MapTypeId.TERRAIN
			            }));
	            });
            });

            this.mapLoaded.then(() => {
	            this.refreshMapData();
            });
        }
    }
}
