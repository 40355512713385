<div class="service">
    <span class="name">{{name}}</span>
    <span class="status" [class.critical]="serviceStatusHistory?.at(-1)?.Status && serviceStatusHistory?.at(-1)?.Status != 'Running'">
        {{serviceStatusHistory?.at(-1)?.Status || 'Loading...'}}
        <span *ngIf="serviceStatusHistory?.at(-1)?.StartupDuration">{{'after'|translate}} {{serviceStatusHistory?.at(-1)?.StartupDuration}} ms</span>
    </span>
    <span class="usage">
        <span class="ram">
            <span class="title">
                {{'RAM'|translate}} {{serviceStatusHistory?.at(-1)?.MemoryUsage ? ((serviceStatusHistory?.at(-1)?.MemoryUsage / 1024 / 1024)|number:"1.1-1") : ''}} MB
            </span>
            <span class="historyGraph">
                <span *ngFor="let h of serviceStatusHistory?.slice(-15); let index = index"
                      class="column"
                      [style.border-left]="(index > 0 ? '1px solid #cccccc' : '')">
                    <span [style.height.%]="h.MemoryUsage === null ? 100 : (Math.max(5, Math.min(h.MemoryUsage / 10000000, 100)))"
                          [style.background-color]="h.MemoryUsage === null ? '#555555' : '#00ff00'"></span>
                </span>
            </span>
        </span>
        <span class="cpu">
            <span class="title">
                {{'CPU'|translate}} {{serviceStatusHistory?.at(-1)?.CPUUsage|number:"1.0-0"}} %
            </span>
            <span class="historyGraph">
                <span *ngFor="let h of serviceStatusHistory?.slice(-15); let index = index"
                      class="column"
                      [style.border-left]="index > 0 ? '1px solid #cccccc' : ''">
                    <span [style.height.%]="h.CPUUsage === null ? 100 : (Math.max(5, Math.min(h.CPUUsage, 100)))"
                          [style.background-color]="h.CPUUsage === null ? '#555555' : (h.CPUUsage > 50 ? '#ffa500' : '#00ff00')"></span>
                </span>
            </span>
        </span>
    </span>
</div>
