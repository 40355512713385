import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import {ChangesService, FiltersComponent, IAgGridColGroupDef, SwxModule} from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import {ServiceMonitoringConditionListComponent} from "./ServiceMonitoringConditionList.component";
import {OrderByPipe, Base64UploadAdapterPlugin} from "swx.front-end-lib";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgIf, NgFor, KeyValuePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
    StationMonitoringConditionListComponent
} from "swx.admin-front-end/stationMonitoringProfile/StationMonitoringConditionList.component";
import {Field, FieldGroup} from "swx.front-end-lib/lib/grid/Query.model";

@Component({
    templateUrl: 'ServiceMonitoringProfileDetail.component.html',
    styleUrls: ['ServiceMonitoringProfileDetail.component.scss'],
    standalone: true,
    imports: [
        SwxModule,
        FormsModule,
        NgIf,
        NgFor,
        ServiceMonitoringConditionListComponent,
        CKEditorModule,
        KeyValuePipe,
        HasPermissionPipe,
        StationMonitoringConditionListComponent,
    ],
})
export class ServiceMonitoringProfileDetailComponent {
    item: any;
    returnPath;
    @ViewChild('ngForm', { static: true }) ngForm;
    @ViewChild('list', { static: true }) list: ServiceMonitoringConditionListComponent;
    tab;
    enteringServiceMonitoringActionTypes = {
        Email: "Email",
        PhoneCall: "PhoneCall",
        Ticket: "Ticket",
        PagerDutyEvent: "PagerDutyEvent",
    };
    exitingServiceMonitoringActionTypes = {
        TicketComment: "TicketComment",
        PagerDutyEvent: "PagerDutyEvent",
    };
    pagetDutyEventSeverities = {
        critical: "critical",
        warning: "warning",
        error: "error",
        info: "info",
    };
    ticketTrackingProjects = this.api.TicketTrackingProject.query();
    emailFroms = this.api.EmailFrom.query();
    Editor = ClassicEditor;
    ckConfig = {
        extraPlugins: [ Base64UploadAdapterPlugin ],
        toolbar: { items: [ 'bold', 'italic','|', 'link', 'imageUpload', 'heading', 'insertTable', 'bulletedList', 'numberedList', 'outdent', 'indent', '|', 'undo', 'redo' ]}
    };
    availableFields: Map<string, Field>;
    fieldGroups: FieldGroup[];
    ibmMqServerConfigurations = this.api.IBMMQServerConfiguration.query();
    baseColumnDefs: IAgGridColGroupDef[] = [
        {
            headerName: "Coordinator",
            children: [
                { field: "CoordinatorServiceRunning", headerName: "Running", filterType: "boolean" },
                { field: "CoordinatorServiceMemoryUsageMB", headerName: "RAM (MB)", filterType: "float", },
                { field: "ServiceStatus.CoordinatorService.CPUUsage", headerName: "CPU (%)", filterType: "float" },
            ]
        },
        {
            headerName: "Data collector",
            children: [
                { field: "DataCollectorServiceRunning", headerName: "Running", filterType: "boolean" },
                { field: "DataCollectorServiceMemoryUsageMB", headerName: "RAM (MB)", filterType: "float" },
                { field: "ServiceStatus.DataCollectorService.CPUUsage", headerName: "CPU (%)", filterType: "float" },
                { field: "LastMetarReadingUpdateAgeMinutes", headerName: "Last METAR update age (minutes)", filterType: "float" },
                { field: "LastSureForecastAllClearAgeMinutes", headerName: "Last Sureforecast AllClear valid from age (minutes)", filterType: "float" },
                { field: "ServiceStatus.DataCollectorService.SureForecastAllClearAirportPercentage", headerName: "Sureforecast AllClear airport coverage (%)", filterType: "float" },
                { field: "LastNowcast12AgeMinutes", headerName: "Last Nowcast LWE12 valid from age (minutes)", filterType: "float" },
                { field: "ServiceStatus.DataCollectorService.Nowcast12AirportPercentage", headerName: "Nowcast LWE12 airport coverage (%)", filterType: "float" },
                { field: "LastOpenMeteoForecastUpdateAgeMinutes", headerName: "Last NOAA RAP report update age (minutes)", filterType: "float" },
                { field: "LastNoaaRapReportUpdateAgeMinutes", headerName: "Last Open-Meteo forecast update age (minutes)", filterType: "float" },
            ],
        },
        {
            headerName: "Communicator",
            children: [
                { field: "CommunicatorServiceRunning", headerName: "Running", filterType: "boolean" },
                { field: "CommunicatorServiceMemoryUsageMB", headerName: "RAM (MB)", filterType: "float" },
                { field: "ServiceStatus.CommunicatorService.CPUUsage", headerName: "CPU (%)", filterType: "float" },
                { field: "FedExShiftAgeHours", headerName: "Last Fedex Shift Received Age (hours)", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.AcarsHotResponseTimeMs", headerName: "ACARS HOT response time (ms)", filterType: "float" },
                { field: "ServiceStatus.CommunicatorService.AcarsHotOnline", headerName: "ACARS HOT response online", filterType: "boolean" },
            ]
        },
        {
            headerName: "Portal",
            children: [
                { field: "ClientPortalsWebServiceRunning", headerName: "Running", filterType: "boolean" },
                { field: "ClientPortalsWebServiceMemoryUsageMB", headerName: "RAM (MB)", filterType: "float" },
                { field: "ServiceStatus.ClientPortalsWebService.CPUUsage", headerName: "CPU (%)", filterType: "float" },
                { field: "ServiceStatus.ClientPortalsWebService.ClientPortalHotResponseTimeMs", headerName: "HOT response time (ms)", filterType: "float" },
                { field: "ServiceStatus.ClientPortalsWebService.ClientPortalHotOnline", headerName: "HOT response online", filterType: "boolean" },
            ]
        },
        {
            headerName: "Mobile API",
            children: [
                { field: "ClientWebServiceRunning", headerName: "Running", filterType: "boolean" },
                { field: "ClientWebServiceMemoryUsageMB", headerName: "RAM (MB)", filterType: "float" },
                { field: "ServiceStatus.ClientWebService.CPUUsage", headerName: "CPU (%)", filterType: "float" },
                { field: "ServiceStatus.ClientWebService.MobileHotResponseTimeMs", headerName: "HOT response time (ms)", filterType: "float" },
                { field: "ServiceStatus.ClientWebService.MobileHotOnline", headerName: "HOT response online", filterType: "boolean" },
                { field: "ServiceStatus.ClientWebService.MobileConfigurationResponseTimeMs", headerName: "Config response time (ms)", filterType: "float" },
                { field: "ServiceStatus.ClientWebService.MobileConfigurationOnline", headerName: "Mobile configuration online", filterType: "boolean" },
                { field: "ServiceStatus.ClientWebService.TwaHotResponseTimeMs", headerName: "TWA HOT response time (ms)", filterType: "float" },
                { field: "ServiceStatus.ClientWebService.TwaHotOnline", headerName: "TWA HOT response online", filterType: "boolean" },
            ]
        },
        {
            headerName: "Admin",
            children: [
                { field: "ConfigurationWebServiceRunning", headerName: "Running", filterType: "boolean" },
                { field: "ConfigurationWebServiceMemoryUsageMB", headerName: "RAM (MB)", filterType: "float" },
                { field: "ServiceStatus.ConfigurationWebService.CPUUsage", headerName: "CPU (%)", filterType: "float" },
            ]
        },
        {
            headerName: "DDMS",
            children: [
                { field: "DeicingManagementWebServiceRunning", headerName: "Running", filterType: "boolean" },
                { field: "DeicingManagementWebServiceMemoryUsageMB", headerName: "RAM (MB)", filterType: "float" },
                { field: "ServiceStatus.DeicingManagementWebService.CPUUsage", headerName: "CPU (%)", filterType: "float" },
            ]
        },
        {
            headerName: "Service provider API",
            children: [
                { field: "ServiceProviderWebServiceRunning", headerName: "Running", filterType: "boolean" },
                { field: "ServiceProviderWebServiceMemoryUsageMB", headerName: "RAM (MB)", filterType: "float" },
                { field: "ServiceStatus.ServiceProviderWebService.CPUUsage", headerName: "CPU (%)", filterType: "float" },
            ]
        },
        {
            headerName: "Data warehouse sync",
            children: [
                { field: "HistoricHotsLastSyncAgeHours", headerName: "HistoricHots last sync age (hours)", filterType: "float" },
                { field: "WPAvailabilitiesLastSyncAgeHours", headerName: "WPAvailabilities last sync age (hours)", filterType: "float" },
                { field: "HistoricHotsRedshiftLastSyncAgeHours", headerName: "HistoricHots Redshift last sync age (hours)", filterType: "float" },
                { field: "WPAvailabilitiesRedshiftLastSyncAgeHours", headerName: "WPAvailabilities Redshift last sync age (hours)", filterType: "float" },
            ],
        },
        {
            headerName: "Queue depths",
            children: [
                { field: "ServiceStatus.CommunicatorService.ACARSMessageQueueDepth", headerName: "ACARS", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.XMLMessageQueueDepth", headerName: "XML", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.AIDXMessageQueueDepth", headerName: "AIDX", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.EmailQueueDepth", headerName: "Email", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.FlightAwareFirehoseQueueDepth", headerName: "Flight aware", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.JMSQueueDepth", headerName: "JMS", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.SQSQueueDepth", headerName: "SQS", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.SFTPServerQueueDepth", headerName: "SFTP", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.BarrelIcingWarningTickQueueDepth", headerName: "Barrel icing", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.ActiveFrostWarningTickQueueDepth", headerName: "Active frost", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.EngineCoverAlertTickQueueDepth", headerName: "Engine cover", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.HeatFlapsAlertQueueDepth", headerName: "Heat flaps alerts AIDX events", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.HeatFlapsAlertTickQueueDepth", headerName: "Heat flaps alerts ticks", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.FlapContaminationAlertQueueDepth", headerName: "Flap contamination alerts AIDX events", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.FlapContaminationAlertTickQueueDepth", headerName: "Flap contamination alerts ticks", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.FreezingFogAlertQueueDepth", headerName: "FZFG alerts OOOIs", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.FreezingFogAlertTickQueueDepth", headerName: "FZFG alerts ticks", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.WeatherAlertQueueDepth", headerName: "Weather alert ticks", filterType: "integer" },
                { field: "ServiceStatus.CommunicatorService.YvrWeatherHotQueueDepth", headerName: "YVR weather HOT ticks", filterType: "integer" },
                { field: "ServiceStatus.DataCollectorService.MetarReadingUpdateQueueDepth", headerName: "METAR", filterType: "integer" },
            ]
        },
    ];
    
    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
        public orderByPipe: OrderByPipe,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';
        
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.ServiceMonitoringProfile.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;

                if (this.item.ServiceMonitoringConditions) {
                    this.item.ServiceMonitoringConditions.forEach(condition => {
                        delete condition.ServiceMonitoringProfileId;

                        var newId = ServiceMonitoringConditionListComponent.uuidv4();
                        this.item.ServiceMonitoringConditions.filter(c => c.ParentConditionId === condition.Id).forEach(child => {
                            child.ParentConditionId = newId;
                        });

                        condition.Id = newId;
                    });
                }

                if (this.item.ServiceMonitoringActions) {
                    this.item.ServiceMonitoringActions.forEach(related => {
                        delete related.ServiceMonitoringProfileId;
                        delete related.Id;
                    });
                }
            });
        } else if (isNew) {
            this.item = this.api.ServiceMonitoringProfile.create({
                Active: true,
            });
        } else {
            this.item = this.api.ServiceMonitoringProfile.get({ id: id });
        }

        if (this.item.$promise) {
            this.item.$promise.then(_ => {
                this.item.ServiceMonitoringConditions = this.item.ServiceMonitoringConditions || [];
                this.item.ServiceMonitoringActions = this.item.ServiceMonitoringActions || [];
                this.item.ServiceMonitoringConditions = this.orderByPipe.transform(this.item.ServiceMonitoringConditions, 'Order');
            });
        }
        
        this.ibmMqServerConfigurations.$promise.then(() => {
            let columnDefs = this.baseColumnDefs.concat(this.ibmMqServerConfigurations.map(c => ({
                headerName: `IBM MQ configuration (${c.Name})`,
                children: [
                    { field: `IBMMQServerConnectionInfos[${c.Id}].LastMessageAgeMinutes`, headerName: "Last message (minutes)", filterType: "float", },
                ]
            })));
            this.availableFields = FiltersComponent.mapColumnDefs(this.api, columnDefs);
            this.fieldGroups = FiltersComponent.getFieldGroups(columnDefs);
        });
    }
    
    save() {
        this.ticketTrackingSystem.trackAndSave(this.item, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show({
            SubjectType: ['ServiceMonitoringProfile'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    addAction(alertState) {
        this.item.ServiceMonitoringActions = this.item.ServiceMonitoringActions || [];
        var maxOrder = Math.max.apply(null, this.item.ServiceMonitoringActions
            .map(h => h.Order));
        this.item.ServiceMonitoringActions.push({
            Order: Math.max(1, maxOrder + 1),
            AlertState: alertState,
        });
        this.ngForm.form.markAsDirty();
    }

    removeAction(action) {
        this.item.ServiceMonitoringActions.splice(this.item.ServiceMonitoringActions.indexOf(action), 1);
        this.ngForm.form.markAsDirty();
    }
}
