import { Injectable } from '@angular/core';
import { AuthenticationService } from 'swx.front-end-lib';

@Injectable({ providedIn: 'root' })
export class RootScope {
    dateTimeFormat = 'YYYY-MM-DD HH:mm';
    dateFormat = 'YYYY-MM-DD';
    timeFormat = 'HH:mm';
    timeAcarsFormatLong = 'DD/MM, HH:mm';
    timeFormatLong = 'HH:mm:ss';
    currentUser = null;

    constructor(
        private authentication: AuthenticationService,
    ) {
        this.authentication.getCurrentUser()
            .then(user => {
                this.currentUser = user;
            });

        this.authentication.userChange.subscribe(user => {
            this.currentUser = user;
        });
    }

    // Temperature conversions
    celsiusToFahrenheit(celsiusTemp: number): number {
        return celsiusTemp * 1.8 + 32;
    }

    fahrenheitToCelsius(fahrenheitTemp: number): number {
        return (fahrenheitTemp - 32) * 5/9;
    }
}
