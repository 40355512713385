<ag-grid-angular class="ag-theme-swx"
                 #agGrid
                 [gridOptions]="gridOptions"
                 [pagination]="true"
                 [suppressPaginationPanel]="true"
                 [columnDefs]="_columnDefs"
                 [rowModelType]="'infinite'"
                 [cacheBlockSize]="cacheBlockSize"
                 [headerHeight]="headerHeight"
                 [rowHeight]="30"
                 [groupHeaderHeight]="20"
                 [rowSelection]="rowSelection"
                 (gridReady)="onGridReady($event)"
                 [components]="components"
                 [suppressCellFocus]="true"
                 (selectionChanged)="selectionChanged?.emit($event)"
                 [alwaysShowHorizontalScroll]="true"
                 [alwaysShowVerticalScroll]="true">
</ag-grid-angular>
<pagination
    #pagination
    [agGrid]="agGrid"
    [pageSizes]="pageSizes">
</pagination>
