@if(!optionsVisible) {
    <div class="display unselectable" (click)="displayValueClick();" [style.width]="width + 'px'">
        {{getDisplayValue()}}
    </div>
} @else {
    <input class="display" type="text" [value]="search" (keyup)="updateSearch($any($event.target).value)" [placeholder]="getDisplayValue()" [style.width]="width + 'px'" />
}
<div (click)="optionsVisible = !optionsVisible; updateSearch('');" class="button mdi mdi-chevron-down">
</div>
@if(optionsVisible) {
    <div class="overlay" (click)="optionsVisible = false; updateSearch('');"></div>
    <div class="options" [style.width]="width + 'px'">
        @for (option of options; track option.value) {
            <label class="option" [style.display]="option.visible ? 'block' : 'none'">
                <input type="checkbox" 
                       [disabled]="max && !isOptionEnabled(option) && selected.length >= max" 
                       [checked]="isOptionEnabled(option)" 
                       (click)="toggleOption($event, option)" />
                {{option.name}}
            </label>
        }
    </div>
}
