<form #form #ngForm="ngForm" (submit)="save()">
    <page-title>{{('User'|translate) + ': ' + (item.Name || 'new')}}</page-title>
	<div id="userEditPage" class="noMargins">
		<div class="toolbar">
			<a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
			<label>
				<input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
			</label>
			<label>
				<input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
			</label>
			<label>
				<input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
			</label>
		</div>

		<fieldset class="contentMargins form" [disabled]="!('ConfigEditUsers'|hasPermission)" [style.display]="tab == 'basicInfo' ? '' :'none'" ngModelGroup="basicInfoForm">
			<label>
				<span class="caption">{{'Name'|translate}}</span>
				<input type="text" required [(ngModel)]="item.Name" name="Name" />
			</label>
			<label>
				<span class="caption">{{'Active'|translate}}</span>
				<input type="checkbox" [(ngModel)]="item.Active" name="Active" />
			</label>
			<label>
				<span class="caption">{{'Email'|translate}}</span>
				<input type="email" required [(ngModel)]="item.Email" name="Email" autocomplete="off" />
			</label>
			<label>
				<span class="caption">{{'Password'|translate}}</span>
				<input type="password" required [(ngModel)]="item.Password" name="Password" autocomplete="off" minlength="6" />
			</label>
			<label>
				<span class="caption">{{'Repeat password'|translate}}</span>
				<input type="password" required [(ngModel)]="RepeatPassword" name="RepeatPassword" autocomplete="off" [password-match]="item.Password" />
			</label>
            <label>
                <span class="caption">{{'Tableau username'|translate}}</span>
                <input type="text" required [(ngModel)]="item.TableauTrustedAuthenticationUsername" name="TableauTrustedAuthenticationUsername" autocomplete="off" />
            </label>
			<label>
				<span class="caption">{{'Mandatory input of ticket and comment'|translate}}</span>
				<input type="checkbox" [(ngModel)]="item.TicketTrackingRequired" name="item.TicketTrackingRequired" />
			</label>
			<label *ngIf="item.TicketTrackingRequired">
				<span class="caption">{{'Ticket Tracker'|translate}}</span>
				<select [(ngModel)]="item.TicketTracker" name="item.TicketTracker">
					<option *ngFor="let tracker of ticketTrackers">{{tracker}}</option>
				</select>
			</label>
			<label *ngIf="item.TicketTrackingRequired && item.TicketTracker === 'Gemini'">
				<span class="caption">{{'Ticket tracking username'|translate}}</span>
				<input type="text" [required]="item.TicketTrackingRequired" [(ngModel)]="item.TicketTrackingUsername" name="item.TicketTrackingUsername" />
			</label>
			<label *ngIf="item.TicketTrackingRequired">
				<span class="caption">{{'Ticket tracking api key'|translate}}</span>
				<input type="text" [required]="item.TicketTrackingRequired" [(ngModel)]="item.TicketTrackingApiKey" name="item.TicketTrackingApiKey" />
			</label>
			<div class="field">
				<span class="caption">{{'Permissions'|translate}}</span>
				<div required>
					<label *ngFor="let option of permissions|keys">
						<input type="checkbox" [checked]="isPermissionEnabled(option)" (click)="togglePermission(option); ngForm.form.markAsTouched();" />{{permissions[option]}}
					</label>
				</div>
			</div>
		</fieldset>
	</div>
</form>
