import {Component, ElementRef, Input, forwardRef, AfterViewInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import {IEnumObject} from "../ApiFactory.service";

interface MultiSelectEnumOption {
    value: string;
    name: string;
    visible: boolean;
}

@Component({
    selector: 'multiselect-enum[enum]',
    styleUrl: 'Multiselect.component.scss',
    templateUrl: 'MultiselectEnum.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiselectEnumComponent),
            multi: true
        }
    ],
    standalone: true,
})
export class MultiselectEnumComponent implements ControlValueAccessor, AfterViewInit {
    @Input('none') noneLabel = 'None';
    @Input('max') max: number;
    @Input('width') width = 272;
    @Input('enum') enum: IEnumObject<string>;
    @Input('format') format = 'string';
    search: string;
    optionsVisible = false;
    selected = [];
    value: any;
    displayValue = null;
    destroyed = false;
    onchange: (_: any) => void = () => { };
    ontouched: (_: any) => void = () => { };
    options: MultiSelectEnumOption[] = [];
    lastNonShiftIndex = -1;

    constructor(
        private el: ElementRef
    ) {
    }

    ngAfterViewInit() {
        if (typeof this.enum.$promise === 'undefined') {
            this.options = Object.entries(this.enum).map(([k, v]) => ({
                value: k,
                name: v as string,
                visible: true,
            }));
        } else {
            this.enum.$promise.then(options => {
                this.options = Object.entries(options).map(([k, v]) => ({
                    value: k,
                    name: v as string,
                    visible: true,
                }));
            });
        }
    }

    isOptionEnabled(option: MultiSelectEnumOption) {
        if (this.selected.length === 0) return false;
        return this.selected.indexOf(option.value) > -1;
    }

    updateSearch(search: string) {
        this.search = search;
        this.options.forEach(o => {
            o.visible = this.search == null
                || this.search === ''
                || o.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
        });
    }

    writeValue(value: any) {
        if (typeof value === 'string') {
            value = value.split(',').map(v => v.trim());
        }

        this.selected = (value || []);
        this.displayValue = null;
        this.value = this.format === 'string'
            ? this.options
                .map(o => o.value)
                .filter(o => this.selected.some(s => s === o))
                .join(',')
            : this.selected;
    }

    toggleOption(e: MouseEvent, option: MultiSelectEnumOption) {
        const target = e.target as HTMLInputElement;
        if (this.selected.length === 1 && this.selected[0] === -1) {
            this.selected.length = 0;
        }

        const index = this.selected.indexOf(option.value);
        
        if (index > -1) {
            this.selected.splice(index, 1);
            target.checked = false;
        } else {
            if (e.shiftKey && this.lastNonShiftIndex > -1) {
                const from = Math.min(this.lastNonShiftIndex, this.options.indexOf(option));
                const to = Math.max(this.lastNonShiftIndex, this.options.indexOf(option));
                const selectedValues = this.options
                    .slice(from, to + 1)
                    .map(o => o.value);
                this.selected = [...new Set([...this.selected, ...selectedValues])];
            }
            else {
                this.selected = [...this.selected, option.value];
                this.lastNonShiftIndex = this.options.indexOf(option);
            }
            
            target.checked = true;
        }

        this.setValue(this.selected);
    }

    setValue(value: any) {
        this.writeValue(value);
        this.onchange(this.value);
        this.ontouched(this.value);
    }

    getDisplayValue() {
        if (this.displayValue === null) {
            if (this.selected.length === 0) {
                this.displayValue = this.noneLabel;
            } else if (this.selected.length === 1 && this.selected[0] === -1) {
                this.displayValue = this.noneLabel;
            } else {
                let displayValue = '';
                this.options.forEach(option => {
                    if (this.selected.indexOf(option.value) > -1) {
                        displayValue += ', ' + option.name;
                    }
                });
                this.displayValue = displayValue.substring(2);
            }
        }

        return this.displayValue;
    }

    displayValueClick() {
        this.optionsVisible = true;
        this.search = '';
        setTimeout(() => this.el.nativeElement.querySelector('input.display').focus());
    }

    registerOnChange(fn: (value: any) => any) {
        this.onchange = fn;
    }

    registerOnTouched(fn) {
        this.ontouched = fn;
    }
}
