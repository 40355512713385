import {Injectable} from "@angular/core";
import { ApiService } from "../shared/Api.service";
import { RootScope } from "../shared/RootScope.service";
import {IAgGridColGroupDef} from "swx.front-end-lib";
import {ICellRendererParams} from "ag-grid-community";


@Injectable({ providedIn: 'root' })
export class ServiceStatusColumnDefinitionsService {
    serviceMonitoringColumns : IAgGridColGroupDef = {
        headerName: "Service monitoring",
        children: [
            { colId: "ServiceStatus.ServiceMonitoringService.Status", field: "ServiceStatus.ServiceMonitoringService.Status", cellRenderer: this.statusCellRenderer, headerName: "Status", width: 80 },
            { colId: "ServiceStatus.ServiceMonitoringService.MemoryUsage", field: "ServiceStatus.ServiceMonitoringService.MemoryUsage", cellRenderer: this.memoryUsageCellRenderer, headerName: "RAM (MB)", width: 50, filterType: "float" },
            { colId: "ServiceStatus.ServiceMonitoringService.CPUUsage", field: "ServiceStatus.ServiceMonitoringService.CPUUsage", cellRenderer: this.cpuUsageCellRenderer, headerName: "CPU (%)", width: 50, filterType: "float" },
        ]
    };
    
    dataCollectorColumns : IAgGridColGroupDef = {
        headerName: "Data collector",
        children: [
            { colId: "ServiceStatus.DataCollectorService.Status", field: "ServiceStatus.DataCollectorService.Status", cellRenderer: this.statusCellRenderer, headerName: "Status", width: 80 },
            { colId: "ServiceStatus.DataCollectorService.MemoryUsage", field: "ServiceStatus.DataCollectorService.MemoryUsage", cellRenderer: this.memoryUsageCellRenderer, headerName: "RAM (MB)", width: 50, filterType: "float" },
            { colId: "ServiceStatus.DataCollectorService.CPUUsage", field: "ServiceStatus.DataCollectorService.CPUUsage", cellRenderer: this.cpuUsageCellRenderer, headerName: "CPU (%)", width: 50, filterType: "float" },
            { colId: "LastMetarReadingUpdateAgeMinutes", field: "LastMetarReadingUpdateAgeMinutes", headerName: "Last METAR update age (minutes)", filterType: "float", width: 120 },
            { colId: "LastSureForecastAllClearAgeMinutes", field: "LastSureForecastAllClearAgeMinutes", headerName: "Last Sureforecast AllClear valid from age (minutes)", filterType: "float", width: 120 },
            { colId: "ServiceStatus.DataCollectorService.SureForecastAllClearAirportPercentage", field: "ServiceStatus.DataCollectorService.SureForecastAllClearAirportPercentage", headerName: "Sureforecast AllClear airport coverage (%)", valueFormatter: c => c.value == null ? "" : (Math.round(c.value * 10) / 10).toString(), filterType: "float", width: 120 },
            { colId: "LastNowcast12AgeMinutes", field: "LastNowcast12AgeMinutes", headerName: "Last Nowcast LWE12 valid from age (minutes)", filterType: "float", width: 120 },
            { colId: "ServiceStatus.DataCollectorService.Nowcast12AirportPercentage", field: "ServiceStatus.DataCollectorService.Nowcast12AirportPercentage", headerName: "Nowcast LWE12 airport coverage (%)", valueFormatter: c => c.value == null ? "" : (Math.round(c.value * 10) / 10).toString(), filterType: "float", width: 120 },
            { colId: "LastOpenMeteoForecastUpdateAgeMinutes", field: "LastOpenMeteoForecastUpdateAgeMinutes", headerName: "Last NOAA RAP report update age (minutes)", filterType: "float", width: 120 },
            { colId: "LastNoaaRapReportUpdateAgeMinutes", field: "LastNoaaRapReportUpdateAgeMinutes", headerName: "Last Open-Meteo forecast update age (minutes)", filterType: "float", width: 120 },
        ]
    };
    
    communicatorColumns : IAgGridColGroupDef = {
        headerName: "Communicator",
        children: [
            { colId: "ServiceStatus.CommunicatorService.Status", field: "ServiceStatus.CommunicatorService.Status", cellRenderer: this.statusCellRenderer, headerName: "Status", width: 80 },
            { colId: "ServiceStatus.CommunicatorService.MemoryUsage", field: "ServiceStatus.CommunicatorService.MemoryUsage", cellRenderer: this.memoryUsageCellRenderer, headerName: "RAM (MB)", width: 50, filterType: "float" },
            { colId: "ServiceStatus.CommunicatorService.CPUUsage", field: "ServiceStatus.CommunicatorService.CPUUsage", cellRenderer: this.cpuUsageCellRenderer, headerName: "CPU (%)", width: 50, filterType: "float" },
            { colId: "ServiceStatus.CommunicatorService.FedExShiftAgeHours", field: "FedExShiftAgeHours", headerName: "Last Fedex Shift Received Age (hours)", filterType: "integer", width: 120 },
            { colId: "ServiceStatus.CommunicatorService.AcarsHotResponseTimeMs", field: "ServiceStatus.CommunicatorService.AcarsHotResponseTimeMs", cellRenderer: c => this.responseTimeCellRenderer(c, c.data?.ServiceStatus.ClientWebService.Status), headerName: "ACARS HOT response time (ms)", width: 100, filterType: "float" },
        ]
    };

    portalColumns : IAgGridColGroupDef = {
        headerName: "Portal",
        children: [
            { colId: "ServiceStatus.ClientPortalsWebService.Status", field: "ServiceStatus.ClientPortalsWebService.Status", cellRenderer: this.statusCellRenderer, headerName: "Status", width: 80 },
            { colId: "ServiceStatus.ClientPortalsWebService.MemoryUsage", field: "ServiceStatus.ClientPortalsWebService.MemoryUsage", cellRenderer: this.memoryUsageCellRenderer, headerName: "RAM (MB)", width: 50, filterType: "float" },
            { colId: "ServiceStatus.ClientPortalsWebService.CPUUsage", field: "ServiceStatus.ClientPortalsWebService.CPUUsage", cellRenderer: this.cpuUsageCellRenderer, headerName: "CPU (%)", width: 50, filterType: "float" },
            { colId: "ServiceStatus.ClientPortalsWebService.ClientPortalHotResponseTimeMs", field: "ServiceStatus.ClientPortalsWebService.ClientPortalHotResponseTimeMs", cellRenderer: c => this.responseTimeCellRenderer(c, c.data?.ServiceStatus.ClientPortalsWebService.Status), headerName: "HOT response time (ms)", width: 100, filterType: "float" },
        ]
    };

    clientColumns : IAgGridColGroupDef = {
        headerName: "Mobile API",
        children: [
            { colId: "ServiceStatus.ClientWebService.Status", field: "ServiceStatus.ClientWebService.Status", cellRenderer: this.statusCellRenderer, headerName: "Status", width: 80 },
            { colId: "ServiceStatus.ClientWebService.MemoryUsage", field: "ServiceStatus.ClientWebService.MemoryUsage", cellRenderer: this.memoryUsageCellRenderer, headerName: "RAM (MB)", width: 50, filterType: "float" },
            { colId: "ServiceStatus.ClientWebService.CPUUsage", field: "ServiceStatus.ClientWebService.CPUUsage", cellRenderer: this.cpuUsageCellRenderer, headerName: "CPU (%)", width: 50, filterType: "float" },
            { colId: "ServiceStatus.ClientWebService.MobileHotResponseTimeMs", field: "ServiceStatus.ClientWebService.MobileHotResponseTimeMs", cellRenderer: c => this.responseTimeCellRenderer(c, c.data?.ServiceStatus.ClientWebService.Status), headerName: "HOT response time (ms)", width: 100, filterType: "float" },
            { colId: "ServiceStatus.ClientWebService.MobileConfigurationResponseTimeMs", field: "ServiceStatus.ClientWebService.MobileConfigurationResponseTimeMs", cellRenderer: c => this.responseTimeCellRenderer(c, c.data?.ServiceStatus.ClientWebService.Status), headerName: "Config response time (ms)", width: 110, filterType: "float" },
            { colId: "ServiceStatus.ClientWebService.TwaHotResponseTimeMs", field: "ServiceStatus.ClientWebService.TwaHotResponseTimeMs", cellRenderer: c => this.responseTimeCellRenderer(c, c.data?.ServiceStatus.ClientWebService.Status), headerName: "TWA HOT response time (ms)", width: 100, filterType: "float" },
        ]
    };

    configurationColumns : IAgGridColGroupDef = {
        headerName: "Admin",
        children: [
            { colId: "ServiceStatus.ConfigurationWebService.Status", field: "ServiceStatus.ConfigurationWebService.Status", cellRenderer: this.statusCellRenderer, headerName: "Status", width: 80 },
            { colId: "ServiceStatus.ConfigurationWebService.MemoryUsage", field: "ServiceStatus.ConfigurationWebService.MemoryUsage", cellRenderer: this.memoryUsageCellRenderer, headerName: "RAM (MB)", width: 50, filterType: "float" },
            { colId: "ServiceStatus.ConfigurationWebService.CPUUsage", field: "ServiceStatus.ConfigurationWebService.CPUUsage", cellRenderer: this.cpuUsageCellRenderer, headerName: "CPU (%)", width: 50, filterType: "float" },
        ]
    };

    ddmsColumns : IAgGridColGroupDef = {
        headerName: "DDMS",
        children: [
            { colId: "ServiceStatus.DeicingManagementWebService.Status", field: "ServiceStatus.DeicingManagementWebService.Status", cellRenderer: this.statusCellRenderer, headerName: "Status", width: 80 },
            { colId: "ServiceStatus.DeicingManagementWebService.MemoryUsage", field: "ServiceStatus.DeicingManagementWebService.MemoryUsage", cellRenderer: this.memoryUsageCellRenderer, headerName: "RAM (MB)", width: 50, filterType: "float" },
            { colId: "ServiceStatus.DeicingManagementWebService.CPUUsage", field: "ServiceStatus.DeicingManagementWebService.CPUUsage", cellRenderer: this.cpuUsageCellRenderer, headerName: "CPU (%)", width: 50, filterType: "float" },
        ]
    };

    serviceProviderColumns : IAgGridColGroupDef = {
        headerName: "Service provider API",
        children: [
            { colId: "ServiceStatus.ServiceProviderWebService.Status", field: "ServiceStatus.ServiceProviderWebService.Status", cellRenderer: this.statusCellRenderer, headerName: "Status", width: 80 },
            { colId: "ServiceStatus.ServiceProviderWebService.MemoryUsage", field: "ServiceStatus.ServiceProviderWebService.MemoryUsage", cellRenderer: this.memoryUsageCellRenderer, headerName: "RAM (MB)", width: 50, filterType: "float" },
            { colId: "ServiceStatus.ServiceProviderWebService.CPUUsage", field: "ServiceStatus.ServiceProviderWebService.CPUUsage", cellRenderer: this.cpuUsageCellRenderer, headerName: "CPU (%)", width: 50, filterType: "float" },
        ]
    };

    dataWarehouseSyncColumns : IAgGridColGroupDef = {
        headerName: "Service provider API",
        children: [
            { field: "HistoricHotsLastSyncAgeHours", headerName: "HistoricHots last sync age (hours)", filterType: "float", width: 120 },
            { field: "WPAvailabilitiesLastSyncAgeHours", headerName: "WPAvailabilities last sync age (hours)", filterType: "float", width: 120 },
            { field: "HistoricHotsRedshiftLastSyncAgeHours", headerName: "HistoricHots Redshift last sync age (hours)", filterType: "float", width: 120 },
            { field: "WPAvailabilitiesRedshiftLastSyncAgeHours", headerName: "WPAvailabilities Redshift last sync age (hours)", filterType: "float", width: 120 },
        ]
    };

    queueDepthColumns : IAgGridColGroupDef = {
        headerName: "Queue depths",
        children: [
            { colId: "ServiceStatus.CommunicatorService.ACARSMessageQueueDepth", field: "ServiceStatus.CommunicatorService.ACARSMessageQueueDepth", cellRenderer: this.queueDepthCellRenderer, headerName: "ACARS", width: 50, filterType: "integer" },
            { colId: "ServiceStatus.CommunicatorService.XMLMessageQueueDepth", field: "ServiceStatus.CommunicatorService.XMLMessageQueueDepth", cellRenderer: this.queueDepthCellRenderer, headerName: "XML", width: 50, filterType: "integer" },
            { colId: "ServiceStatus.CommunicatorService.AIDXMessageQueueDepth", field: "ServiceStatus.CommunicatorService.AIDXMessageQueueDepth", cellRenderer: this.queueDepthCellRenderer, headerName: "AIDX", width: 50, filterType: "integer" },
            { colId: "ServiceStatus.CommunicatorService.EmailQueueDepth", field: "ServiceStatus.CommunicatorService.EmailQueueDepth", cellRenderer: this.queueDepthCellRenderer, headerName: "Email", width: 50, filterType: "integer" },
            { colId: "ServiceStatus.CommunicatorService.FlightAwareFirehoseQueueDepth", field: "ServiceStatus.CommunicatorService.FlightAwareFirehoseQueueDepth", cellRenderer: this.queueDepthCellRenderer, headerName: "Flight aware", width: 50, filterType: "integer" },
            { colId: "ServiceStatus.CommunicatorService.JMSQueueDepth", field: "ServiceStatus.CommunicatorService.JMSQueueDepth", cellRenderer: this.queueDepthCellRenderer, headerName: "JMS", width: 50, filterType: "integer" },
            { colId: "ServiceStatus.CommunicatorService.SQSQueueDepth", field: "ServiceStatus.CommunicatorService.SQSQueueDepth", cellRenderer: this.queueDepthCellRenderer, headerName: "SQS", width: 50, filterType: "integer" },
            { colId: "ServiceStatus.CommunicatorService.SFTPServerQueueDepth", field: "ServiceStatus.CommunicatorService.SFTPServerQueueDepth", cellRenderer: this.queueDepthCellRenderer, headerName: "SFTP", width: 50, filterType: "integer" },
            { colId: "ServiceStatus.CommunicatorService.BarrelIcingWarningTickQueueDepth", field: "ServiceStatus.CommunicatorService.BarrelIcingWarningTickQueueDepth", cellRenderer: this.queueDepthCellRenderer, headerName: "Barrel icing", width: 50, filterType: "integer" },
            { colId: "ServiceStatus.CommunicatorService.ActiveFrostWarningTickQueueDepth", field: "ServiceStatus.CommunicatorService.ActiveFrostWarningTickQueueDepth", cellRenderer: this.queueDepthCellRenderer, headerName: "Active frost", width: 50, filterType: "integer" },
            { colId: "ServiceStatus.CommunicatorService.EngineCoverAlertTickQueueDepth", field: "ServiceStatus.CommunicatorService.EngineCoverAlertTickQueueDepth", cellRenderer: this.queueDepthCellRenderer, headerName: "Engine cover", width: 60, filterType: "integer" },
            { colId: "ServiceStatus.CommunicatorService.HeatFlapsAlertQueueDepth", field: "ServiceStatus.CommunicatorService.HeatFlapsAlertQueueDepth", headerName: "Heat flaps alerts AIDX events", cellRenderer: this.queueDepthCellRenderer, width: 80, filterType: "integer" },
            { colId: "ServiceStatus.CommunicatorService.HeatFlapsAlertTickQueueDepth", field: "ServiceStatus.CommunicatorService.HeatFlapsAlertTickQueueDepth", headerName: "Heat flaps alerts ticks", cellRenderer: this.queueDepthCellRenderer, width: 60, filterType: "integer" },
            { colId: "ServiceStatus.CommunicatorService.FreezingFogAlertQueueDepth", field: "ServiceStatus.CommunicatorService.FreezingFogAlertQueueDepth", headerName: "FZFG alerts OOOIs", cellRenderer: this.queueDepthCellRenderer, width: 60, filterType: "integer" },
            { colId: "ServiceStatus.CommunicatorService.FreezingFogAlertTickQueueDepth", field: "ServiceStatus.CommunicatorService.FreezingFogAlertTickQueueDepth", headerName: "FZFG alerts ticks", cellRenderer: this.queueDepthCellRenderer, width: 60, filterType: "integer" },
            { colId: "ServiceStatus.DataCollectorService.MetarReadingUpdateQueueDepth", field: "ServiceStatus.DataCollectorService.MetarReadingUpdateQueueDepth", cellRenderer: this.queueDepthCellRenderer, headerName: "METAR", width: 60, filterType: "integer" },
        ]
    };

    constructor(
        public $root: RootScope,
        public api: ApiService,
    ) {
    }

    memoryUsageCellRenderer(c : ICellRendererParams) : HTMLElement {
        var container = document.createElement('div');
        if (c.value != null) {
            container.innerHTML = Math.round(c.value / 1024 / 1024).toString();
        }
        return container;
    }

    cpuUsageCellRenderer(c : ICellRendererParams) : HTMLElement {
        var container = document.createElement('div');
        if (c.value != null) {
            container.innerHTML = (Math.round(c.value * 10) / 10).toString();
            if (c.value > 50) {
                container.style.color = '#ffa500';
                container.style.fontWeight = 'bold';
            }
        }
        return container;
    }

    statusCellRenderer(c : ICellRendererParams) : HTMLElement {
        var container = document.createElement('div');
        if (c.value != null) {
            container.innerHTML = c.value;
            if (c.value === 'Running') {
                container.style.color = '#00aa00';
            } else if (c.value === 'Offline') {
                container.style.color = '#ff0000';
                container.style.fontWeight = 'bold';
            } else {
                container.style.color = '#ffa500';
                container.style.fontWeight = 'bold';
            }
        }
        return container;
    }

    queueDepthCellRenderer(c : ICellRendererParams) : HTMLElement {
        var container = document.createElement('div');
        if (c.value != null) {
            container.innerHTML = c.value;
            if (c.value > 50) {
                container.style.color = '#ff0000';
                container.style.fontWeight = 'bold';
            }
        }
        return container;
    }

    responseTimeCellRenderer(c : ICellRendererParams, serviceStatus: string) : HTMLElement {
        var container = document.createElement('div');
        if (c.value != null) {
            container.innerHTML = c.value;
            if (c.value > 5000) {
                container.style.color = '#ff0000';
                container.style.fontWeight = 'bold';
            } else if (c.value > 1000) {
                container.style.color = '#ffa500';
                container.style.fontWeight = 'bold';
            }
        } else if (serviceStatus === 'Running') {
            container.innerHTML = 'Offline';
            container.style.color = '#ff0000';
            container.style.fontWeight = 'bold';
        }
        return container;
    }
}
