<div id="serviceStatusPage" class="noMargins">
    <page-title>{{('Service monitoring alerts'|translate)}}</page-title>
    <form #form="ngForm" (submit)="refresh()" class="toolbar gridFilters">
        <label class="inline">
            <span class="caption">{{'Service monitoring profile'|translate}}</span>
            <multiselect [(ngModel)]="query.ServiceMonitoringProfileId" name="ServiceMonitoringProfileId">
                <multiselect-option *ngFor="let option of serviceMonitoringProfiles|orderBy:'Name'" [value]="option.Id" [name]="option.Name"></multiselect-option>
            </multiselect>
        </label>
        <label class="inline">
            <span class="caption">{{'From'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.FromDate" name="FromDate"/>
        </label>
        <label class="inline">
            <span class="caption">{{'To'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="ToDate"/>
        </label>
        <filters [(ngModel)]="query.Filters" [columnDefs]="columnDefs" name="query.Filters"></filters>
        <button type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
        <button type="button" (click)="refresh()" [disabled]="!form.valid"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
        <button type="button" (click)="grid.export()" [disabled]="!form.valid" ><span class="mdi mdi-download"></span>{{'Export'|translate}}</button>
        <button type="button" (click)="grid.selectColumns()" [disabled]="!form.valid" ><span class="mdi mdi-table-edit"></span>{{'Columns'|translate}}</button>
    </form>

    <grid #grid
          gridId="serviceMonitoringAlerts"
          [parent]="this"
          [query]="query"
          [resource]="api.ServiceMonitoringAlert"
          [exportResource]="api.ServiceMonitoringAlertExport"
          [columnDefs]="columnDefs">
    </grid>
</div>
