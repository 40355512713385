import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode, importProvidersFrom } from '@angular/core';

import { environment } from './environments/environment';
import { AppComponent } from './app.component';
import { AgGridModule } from 'ag-grid-angular';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/Dashboard.component';
import { ServiceMonitoringProfileListComponent } from './serviceMonitoringProfile/ServiceMonitoringProfileList.component';
import { ServiceMonitoringProfileDetailComponent } from './serviceMonitoringProfile/ServiceMonitoringProfileDetail.component';
import { ServiceMonitoringAlertListComponent } from './serviceMonitoringAlert/ServiceMonitoringAlertList.component';
import { ServiceStatusComponent } from './serviceStatus/ServiceStatus.component';
import { LoginHistoryComponent } from './loginHistory/LoginHistory.component';
import { Saml2ErrorComponent } from './login/Saml2Error.component';
import { ChangeGridComponent } from './change/ChangeGrid.component';
import { UserListComponent } from './user/UserList.component';
import { UserDetailComponent } from './user/UserDetail.component';
import { LoginComponent } from './login/Login.component';
import { provideRouter } from '@angular/router';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { ApiService } from './shared/Api.service';
import { TicketTrackingSystemService } from './shared/TicketTrackingSystem.service';
import { AuthInterceptorService, TranslatePipe, ExportApi, GridModule, SwxModule, AuthModule, ChangesModule } from 'swx.front-end-lib';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthGuard } from './shared/AuthGuard.service';
import { HasPermissionService } from './shared/HasPermission.pipe';
import { DecimalPipe } from '@angular/common';

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, FormsModule, ReactiveFormsModule, CKEditorModule, GridModule, AgGridModule, SwxModule.forRoot(environment), AuthModule.forRoot({
            tokenUrl: `${environment.apiUrl}/oauth/access_token`,
            userUrl: `${environment.apiUrl}/user/me`,
            storageKey: 'access_token',
        }), ChangesModule.forRoot(environment)),
        DecimalPipe,
        HasPermissionService,
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
        TicketTrackingSystemService,
        TranslatePipe,
        {
            provide: ExportApi,
            useClass: ApiService,
        },
        provideHttpClient(withInterceptorsFromDi()),
        provideRouter([
            { path: 'login', component: LoginComponent },
            { path: 'users/:id', component: UserDetailComponent, canActivate: [AuthGuard] },
            { path: 'users', component: UserListComponent, canActivate: [AuthGuard] },
            { path: 'changes', component: ChangeGridComponent, canActivate: [AuthGuard] },
            { path: 'saml2Error', component: Saml2ErrorComponent },
            { path: 'loginHistory', component: LoginHistoryComponent, canActivate: [AuthGuard] },
            { path: 'serviceStatus', component: ServiceStatusComponent, canActivate: [AuthGuard] },
            { path: 'serviceMonitoringAlerts', component: ServiceMonitoringAlertListComponent, canActivate: [AuthGuard] },
            { path: 'serviceMonitoringProfiles/:id', component: ServiceMonitoringProfileDetailComponent, canActivate: [AuthGuard] },
            { path: 'serviceMonitoringProfiles', component: ServiceMonitoringProfileListComponent, canActivate: [AuthGuard] },
            { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
            { path: '**', component: DashboardComponent, canActivate: [AuthGuard] },
        ])
    ]
})
    .catch(err => console.error(err));