import {Component, Inject, OnInit} from "@angular/core";
import {IAgGridColGroupDef, IAgGridColumnDef, ColumnPreferenceGroup, ColumnPreference} from '../Query.model';
import { ColumnPreferencesService } from '../ColumnPreferences.service';
import { TranslatePipe } from "../../pipes/Translate.pipe";
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";
import {DialogComponent, DialogContentComponent, DialogFooterComponent, DialogHeaderComponent} from "../../dialog";
import {CdkDrag} from "@angular/cdk/drag-drop";
import {ColumnSelectionDialogData} from "./ColumnSelectionDialogData";

@Component({
    templateUrl: "./ColumnSelectionDialog.component.html",
    styleUrls: ['ColumnSelectionDialog.component.scss'],
    standalone: true,
    imports: [TranslatePipe, DialogComponent, DialogHeaderComponent, DialogContentComponent, DialogFooterComponent, CdkDrag]
})
export class ColumnSelectionDialogComponent implements OnInit {
    columnPreferences: ColumnPreferenceGroup[];
    selectedCount: number;
    totalCount: number;
    infoMessage: string;
    columnDefs: (IAgGridColumnDef | IAgGridColGroupDef)[];
    columnPreferencesStorageKey: string;

    constructor(
        @Inject(DIALOG_DATA) private data: ColumnSelectionDialogData,
        private dialogRef: DialogRef<ColumnPreferenceGroup[]>,
        private columnPreferencesService: ColumnPreferencesService,
    )
    { }
    
    ngOnInit(): void {
        this.columnPreferencesStorageKey = this.data.columnPreferencesStorageKey;
        this.columnDefs = this.data.columnDefs;
        this.columnPreferences = this.columnPreferencesService
            .loadColumnPreferences(this.columnPreferencesStorageKey + '_selected', this.columnDefs)
            .map(g => ({
                ...g,
                children: g.children.filter(c => c.initialHide == null || !c.initialHide),
            }));
        
        this.updateTotals();
    }
    
    close() {
        this.dialogRef.close(this.columnPreferences);
    }
    
    confirmSelection() {
        this.columnPreferencesService.savePreferences(this.columnPreferencesStorageKey + '_selected', this.columnPreferences);
        this.close();
    }
    
    // Check/uncheck column
    toggleSelected(checked: boolean, column: ColumnPreference) {
        column.selected = checked;
        this.updateTotals();
    }

    // Check/uncheck everything
    toggleSelectedAll(checked: boolean) {
        this.columnPreferences.forEach(g => g.children.forEach(c => c.selected = checked));
        this.updateTotals();
    }

    // Check/uncheck everything in group
    toggleSelectedAllGroup(checked: boolean, group: ColumnPreferenceGroup) {
        group.children.forEach(c => c.selected = checked);
        this.updateTotals();
    }

    // Helper: Returns an appropriate text message depending on toggle selection
    updateTotals() {
        this.selectedCount = this.columnPreferences.reduce((sum, group) => sum + group.children.filter(c => c.selected || c.alwaysSelected).length, 0);
        this.totalCount = this.columnPreferences.reduce((sum, group) => sum + group.children.length, 0);
        this.columnPreferences.forEach(g => g.allSelected = g.children.every(c => c.selected || c.alwaysSelected));
        
        if (this.selectedCount === 0) {
            this.infoMessage = 'None selected';
        } else if (this.selectedCount === this.totalCount) {
            this.infoMessage = 'All selected';
        } else {
            this.infoMessage = 'Custom selection';
        }
    }
}
