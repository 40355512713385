import {Component, Input} from "@angular/core";
import { NgIf } from "@angular/common";

@Component({
    template: `
    <textarea *ngIf="!html" readonly style="width: calc(100% - 20px); font-family: monospace; height: 620px; white-space: pre-wrap; overflow-y: auto;">{{rawData}}</textarea>
    <div *ngIf="html" style="calc(100% - 20px); height: 620px; overflow: auto; word-wrap: break-word;" [innerHTML]="html"></div>`,
    standalone: true,
    imports: [NgIf]
})
export class RawDataDialogComponent {
    @Input() rawData: string;
    @Input() html: string;
}
