import { Injectable } from '@angular/core';
import { IResourceItem, ApiFactoryService, ExportApi } from 'swx.front-end-lib';

@Injectable({ providedIn: 'root' })
export class ApiService implements ExportApi {
    constructor(
        private apiFactory: ApiFactoryService,
    ) {
    }

    // enums
    Permission = this.apiFactory.enumResourceFactory('/Permission');
    SubjectType = this.apiFactory.enumResourceFactory('/SubjectType');
    ServiceMonitoringActionType = this.apiFactory.enumResourceFactory('/ServiceMonitoringActionType');
    
    // resources
    User = this.apiFactory.resourceFactory<any>('/User');
    UserExport = this.apiFactory.resourceFactory<any>('/UserExport');
    Change = this.apiFactory.resourceFactory<any>('/Change');
    ChangeExport = this.apiFactory.resourceFactory<any>('/ChangeExport');
    ChangeAlert = this.apiFactory.resourceFactory<any>('/ChangeAlert');
    UserAuthentication = this.apiFactory.resourceFactory<any>('/UserAuthentication');
    UserAuthenticationExport = this.apiFactory.resourceFactory<any>('/UserAuthenticationExport');
    SamlLoginRequest = this.apiFactory.resourceFactory<any>('/Saml/LoginRequest');
    SamlLogoutRequest = this.apiFactory.resourceFactory<any>('/Saml/LogoutRequest');
    ExcelExportTask = this.apiFactory.resourceFactory<any>('/ExcelExportTask');
    ExcelExportResult = this.apiFactory.resourceFactory<any>('/ExcelExportResult');
    AuthenticationConfiguration = this.apiFactory.resourceFactory<any>('/AuthenticationConfiguration');
    ServiceMonitorStatus = this.apiFactory.resourceFactory<any>('/ServiceMonitorStatus');
    ServiceStatus = this.apiFactory.resourceFactory<any>('/ServiceStatus');
    ServiceStatusExport = this.apiFactory.resourceFactory<any>('/ServiceStatusExport');
    TableauTrustedAuthenticationToken = this.apiFactory.resourceFactory<any>('/TableauTrustedAuthenticationToken');
    ServiceMonitoringProfile = this.apiFactory.resourceFactory<any>('/ServiceMonitoringProfile');
    ServiceMonitoringAlert = this.apiFactory.resourceFactory<any>('/ServiceMonitoringAlert');
    ServiceMonitoringAlertExport = this.apiFactory.resourceFactory<any>('/ServiceMonitoringAlertExport');
    EmailFrom = this.apiFactory.resourceFactory<any>('/EmailFrom');
    IBMMQServerConfiguration = this.apiFactory.resourceFactory<any>('/IBMMQServerConfiguration');
    TicketTrackingProject = this.apiFactory.resourceFactory<any>('/TicketTrackingProject');
}
