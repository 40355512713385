<ul>
    <li><a routerLink="/dashboard" routerLinkActive="active" *ngIf="'Dashboard'|hasPermission">{{'Dashboard'|translate}}</a></li>
    <li><a routerLink="/serviceStatus" routerLinkActive="active" *ngIf="'ServiceStatusHistory'|hasPermission">{{'Status history'|translate}}</a></li>
    <li><a routerLink="/serviceMonitoringAlerts" routerLinkActive="active" *ngIf="'ServiceStatusHistory'|hasPermission">{{'Alert history'|translate}}</a></li>
    <li *ngIf="currentUser?.Permissions.indexOf('Config') > -1">
        <h3>{{'Configuration'|translate}}</h3>
        <ul>
            <li><a routerLink="/users" routerLinkActive="active" *ngIf="'ConfigViewUsers'|hasPermission">{{'Users'|translate}}</a></li>
            <li><a routerLink="/serviceMonitoringProfiles" routerLinkActive="active" *ngIf="'ConfigViewServiceMonitoringProfiles' | hasPermission">{{'Monitoring profiles'|translate}}</a></li>
            <li><a routerLink="/changes" routerLinkActive="active" *ngIf="'DataChanges'|hasPermission">{{'Changes'|translate}}</a></li>
            <li><a routerLink="/loginHistory" routerLinkActive="active" *ngIf="'DataLogins' | hasPermission">{{'Login history'|translate}}</a></li>
        </ul>
    </li>
</ul>
