import {Component, ViewChild, OnInit, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { ChangesService, SwxModule } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'UserDetail.component.html',
    standalone: true,
    imports: [FormsModule, SwxModule, NgIf, NgFor, HasPermissionPipe]
})
export class UserDetailComponent implements OnInit {
    item;
    returnPath;
    permissions = this.api.Permission.query();
    RepeatPassword;
    @ViewChild('ngForm', { static: true }) ngForm;
    tab;
    ticketTrackers = ['Gemini', 'YouTrack'];

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
    ) {
    }

    ngOnInit() {
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.User.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;
                delete this.item.Name;
                delete this.item.Username;
                delete this.item.Email;
                delete this.item.Password;
                delete this.RepeatPassword;
                delete this.item.Comments;
                delete this.item.TicketTrackingUserId;
                delete this.item.TicketTrackingUsername;
                delete this.item.TicketTrackingApiKey;

                if (this.item.DeicingUserDeicingServiceProviders) {
                    this.item.DeicingUserDeicingServiceProviders.forEach(related => {
                        delete related.DeicingUserId;
                    });
                }

                if (this.item.DeicingUserDeicingAirlines) {
                    this.item.DeicingUserDeicingAirlines.forEach(related => {
                        delete related.DeicingUserId;
                    });
                }
            });
            this.RepeatPassword = '__PASSWORD__';
        } else if (isNew) {
            this.item = this.api.User.create({
                AirlineId: null,
                Active: true,
                LoginRequired: true
            });
        } else {
            this.item = this.api.User.get({ id: id });
            this.RepeatPassword = '__PASSWORD__';
        }

        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';
    }
    
    save() {
        this.ticketTrackingSystem.trackAndSave(this.item, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show({
            SubjectType: ['User'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    isPermissionEnabled(option) {
        const selected = (this.item.Permissions || '').split(', ');
        return selected.indexOf(option) > -1;
    };

    togglePermission(option) {
        const selected = this.item.Permissions ? this.item.Permissions.split(', ') : [];
        const index = selected.indexOf(option);
        if (index > -1) {
            selected.splice(index, 1);
        } else {
            selected.push(option);
        }
        this.item.Permissions = selected.join(', ');
        this.ngForm.form.markAsDirty();
    }
}
