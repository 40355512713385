import {Component, Inject, OnInit} from '@angular/core';
import { TranslatePipe } from '../pipes/Translate.pipe';
import {NgStyle} from '@angular/common';
import {DIALOG_DATA} from "@angular/cdk/dialog";
import {DialogComponent, DialogContentComponent, DialogHeaderComponent} from "../dialog";
import {CdkDrag, CdkDragHandle} from "@angular/cdk/drag-drop";

@Component({
    templateUrl: './DiffDialog.component.html',
    standalone: true,
    imports: [TranslatePipe, DialogComponent, DialogHeaderComponent, DialogContentComponent, CdkDrag, CdkDragHandle, NgStyle]
})
export class DiffDialogComponent implements OnInit {
    diff!: any;
    
    constructor(@Inject(DIALOG_DATA) private data: any) {
    }
    
    ngOnInit() {
        this.diff = this.data.diff;
    }
}
