<swx-dialog>
    <swx-dialog-header>
        Comments
    </swx-dialog-header>
    <swx-dialog-content>
        <form class="form" #form #ngForm="ngForm" (submit)="save()">
            <fieldset class="contentMargins">
                <label>
                    <span class="caption">{{'Ticket Number'|translate}}</span>
                    <input type="number" min="0" required [(ngModel)]="ticketNumber" name="ticketNumber" style="width: 100px;" />
                </label>
                <label>
                    <span class="caption">{{'Comments'|translate}}</span>
                    <textarea required [(ngModel)]="comments" name="comments" style="width: 400px; height: 75px;"></textarea>
                </label>
                <label>
                    <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
                    <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
                </label>
            </fieldset>
        </form>
    </swx-dialog-content>
</swx-dialog>
