import {Component, EventEmitter, Input, OnDestroy, Output, ViewContainerRef, ViewEncapsulation} from '@angular/core';
import {GridApi, GridReadyEvent, GridOptions, SelectionChangedEvent} from 'ag-grid-community';
import { QuerySerializerService } from "../QuerySerializer.service";
import { ExportApi, IResource, IResourceItemList } from "../ApiFactory.service";
import { ColumnPreferencesService } from "./ColumnPreferences.service";
import { ExportDialogComponent } from './ExportDialog.component';
import { DialogService } from "../Dialog.service";
import { StorageService } from "../Storage.service";
import {ColumnPreferenceGroup, IAgGridColGroupDef, IAgGridColumnDef, IBaseQuery} from "./Query.model";
import { CellTemplateService } from "./CellTemplate.service";
import {ColumnSelectionDialogComponent} from "./ColumnSelectionDialog.component";
import { PaginationComponent } from './Pagination.component';
import { AgGridModule } from 'ag-grid-angular';

@Component({
    selector: 'grid[gridId][columnDefs][resource]',
    templateUrl: 'Grid.component.html',
    styleUrls: ['./Grid.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [AgGridModule, PaginationComponent],
})
export class GridComponent implements OnDestroy {
    @Input() gridId: string;
    private componentRefs;
    protected _columnDefs;
    @Input() set columnDefs(columnDefs: IAgGridColumnDef[] | IAgGridColGroupDef[]) {
        if (this.parent != null) {
            this.componentRefs = this.cellTemplateService.transformColumnDefCellTemplates(this.parent, this.viewContainerRef, columnDefs);
        }
         if (this.gridId) {
            let columnPreferences = this.columnPreferencesService.loadColumnPreferences(this.gridId + '_selected', columnDefs);
            columnPreferences.forEach(g => {
                g.children.forEach(c => {
                    c.hide = !c.selected && c.alwaysSelected != true;
                });
            });
            this._columnDefs = columnPreferences;
        } else {
            this._columnDefs = columnDefs;
        }
    }
    get columnDefs(): any {
        return this._columnDefs;
    }
    @Input('gridOptions') extraGridOptions: GridOptions = {};
    @Input() rowSelection = 'single';
    @Input() headerHeight = 72;
    @Input() cacheBlockSize = 100;
    @Input() components: any = {};
    @Input() query: IBaseQuery = {};
    @Input() resource: IResource<any>;
    @Input() exportResource: IResource<any>;
    private _parent: any;
    @Input()
    set parent(parent: any) {
        this._parent = parent;
        if (this._columnDefs != null) {
            this.componentRefs = this.cellTemplateService.transformColumnDefCellTemplates(this.parent, this.viewContainerRef, this._columnDefs);
        }
    }
    get parent(): any {
        return this._parent;
    }
    @Output() selectionChanged = new EventEmitter<SelectionChangedEvent>();
    @Output() dataLoaded = new EventEmitter<IResourceItemList<any>>();
    pageSizes: number[] = [100, 200, 300];
    gridApi: GridApi;
    
    get gridOptions(): GridOptions {
        const initialPageSize = this.pageSizes[0];
        return {
            cacheBlockSize: initialPageSize,
            paginationPageSize: initialPageSize,
            
            defaultColDef: {
                sortable: true,
                resizable: true,
            },
            ...this.extraGridOptions,
        };
    };
    gridReadyPromise: Promise<void>;
    private gridReadyResolve;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private cellTemplateService: CellTemplateService,
        private querySerializerService: QuerySerializerService,
        private columnPreferencesService: ColumnPreferencesService,
        private dialogService: DialogService,
        private storageService: StorageService,
        private api: ExportApi,
    ) {
        this.gridReadyPromise = new Promise<void>(resolve => {
            this.gridReadyResolve = resolve;
        })
    }

    ngOnDestroy() {
        if (this.componentRefs != null) {
            this.cellTemplateService.cleanup(this.componentRefs);
        }
    }

    onGridReady({ api } : GridReadyEvent) {
        this.gridApi = api;
        this.gridReadyResolve();
    }

    export() {
        this.dialogService.show(this.viewContainerRef, ExportDialogComponent,
            {
                columnPreferencesStorageKey: this.gridId,
                api: this.api,
                exportResource: this.exportResource,
                storageService: this.storageService,
                query: this.query,
                columnDefs: this._columnDefs,
            },
            {
                title: 'Export',
                width: 640,
                height: 640,
                modal: true,
                dialogClass: 'flexDialog'
            });
    }
    
    selectColumns() {
        this.dialogService.show(this.viewContainerRef, ColumnSelectionDialogComponent,
            {
                columnPreferencesStorageKey: this.gridId,
                storageService: this.storageService,
                columnDefs: this._columnDefs,
                selectionConfirmed: (columnDefs: ColumnPreferenceGroup[]) => {
                    this.columnDefs = columnDefs;
                },
            },
            {
                title: 'Select columns',
                width: 640,
                height: 640,
                modal: true,
                dialogClass: 'flexDialog'
            });
    }

    refresh(): Promise<void> {
        const self = this;
        return new Promise((resolve) => {
            this.gridApi.setDatasource({
                rowCount: null,
                getRows(params) {
                    self.gridApi.showLoadingOverlay();

                    const sortField = params.sortModel[0]
                        ? ColumnPreferencesService.normalizeColumnDefs(self._columnDefs)
                            .map(g => g.children.filter(c => c.colId === params.sortModel[0].colId))
                            .filter(cs => cs.length > 0)[0][0].field
                        : '';

                    const queryParams = self.querySerializerService.toHttpParams({
                        ...self.query,
                        SortField: sortField,
                        SortDirection: params.sortModel[0] ? params.sortModel[0].sort : '',
                        PageNumber: 1 + Math.floor(params.startRow / (params.endRow - params.startRow)),
                        PageSize: params.endRow - params.startRow
                    });

                    self.resource
                        .query(queryParams)
                        .$promise
                        .then(response => {
                            var total = parseInt(response.$headers.get('x-total'));
                            params.successCallback(response, total);

                            self.dataLoaded?.emit(response);

                            self.gridApi.hideOverlay();
                            resolve(undefined);
                        });
                }
            });
        });
    }
}
