import {Component, OnInit, ViewChild, ViewEncapsulation, ElementRef} from "@angular/core";
import { ApiService } from "../shared/Api.service";
import { GridComponent, IAgGridColGroupDef, BusyIndicatorService, SwxModule, GridModule } from "swx.front-end-lib";
import { RootScope } from "../shared/RootScope.service";
import moment from 'moment';
import {ServiceStatusColumnDefinitionsService} from "./ServiceStatusColumnDefinitions.service";
import { NgIf } from "@angular/common";
import { FormsModule } from "@angular/forms";

declare var tableau: any;

@Component({
    templateUrl: './ServiceStatus.component.html',
    styleUrls: ['./ServiceStatus.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        SwxModule,
        FormsModule,
        NgIf,
        GridModule,
    ],
})
export class ServiceStatusComponent implements OnInit {
    Math = Math;
    Object = Object;
    config: any;
    tab: string = 'history';
    query: any;
    datePickerOptions: DateTimePickerOptions;
    @ViewChild('historyGrid', { static: true }) historyGrid: GridComponent;
    @ViewChild('graphs', { static: true }) graphs: ElementRef<HTMLElement>;
    viz: any;

    historyColumnDefs : IAgGridColGroupDef[] = [
        {
            headerName: "",
            children: [
                { colId: "Date", field: "ServiceStatus.DateTime", headerName: "Date", width: 90, valueFormatter: c => c.value ? moment.utc(c.value).format(this.$root.dateFormat) : "", pinned: "left" },
                { colId: "Time", field: "ServiceStatus.DateTime", headerName: "Time", width: 60, valueFormatter: c => c.value ? moment.utc(c.value).format(this.$root.timeFormat) : "", excelIgnore: true, pinned: "left" },
            ]
        },
        this.serviceStatusColumns.serviceMonitoringColumns,
        this.serviceStatusColumns.dataCollectorColumns,
        this.serviceStatusColumns.communicatorColumns,
        this.serviceStatusColumns.portalColumns,
        this.serviceStatusColumns.clientColumns,
        this.serviceStatusColumns.configurationColumns,
        this.serviceStatusColumns.ddmsColumns,
        this.serviceStatusColumns.serviceProviderColumns,
        this.serviceStatusColumns.dataWarehouseSyncColumns,
        this.serviceStatusColumns.queueDepthColumns,
    ];

    constructor(
        public $root: RootScope,
        public api: ApiService,
        private serviceStatusColumns: ServiceStatusColumnDefinitionsService,
        public busyIndicator: BusyIndicatorService,
    ) {
    }

    ngOnInit(): void {
        this.datePickerOptions = {
            maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        };

        if (window.localStorage['serviceStatusQuery']) {
            this.query = JSON.parse(window.localStorage['serviceStatusQuery']);
        } else {
            this.resetQuery();
        }

        this.tab = location.hash ? location.hash.substring(1) : 'history';
        this.switchTab(this.tab);
    }

    resetQuery() {
        this.query = {
            Filters: [],
            FromDate: null,
            ToDate: null,
        };
    }

    switchTab(tab: string) {
        location.hash = tab;
        this.tab = tab;

        if (this.tab === 'graphs') {
            let viewUrl = 'https://dashboards.surewx.com/views/ServiceStatus20240403-01/ServiceStatus';

            this.api.TableauTrustedAuthenticationToken.queryObject().$promise.then(response => {
                var trustedUrl = viewUrl.replace('/views/',
                    '/trusted/' + response.token + '/views/');

                if (this.viz != null) {
                    this.viz.dispose();
                    this.viz = null;
                }

                var containerDiv = this.graphs.nativeElement;

                this.busyIndicator.setBusy(true);

                var options = {
                    hideTabs: true,
                    hideToolbar: true,
                    device: "desktop",
                    onFirstInteractive: () => {
                        this.busyIndicator.setBusy(false);
                    }
                };

                this.viz = new tableau.Viz(containerDiv, trustedUrl, options);
            });
        }
    }

    refresh() {
        window.localStorage['serviceStatusQuery'] = JSON.stringify(this.query);

        if (this.tab == 'history') {
            this.historyGrid.refresh();
        }
    }
    
    export() {
        if (this.tab == 'history') {
            this.historyGrid.export();
        }
    }
}
