<swx-dialog cdkDrag>
    <swx-dialog-header>Export</swx-dialog-header>
    <swx-dialog-content>
        <div class="export-dialog-content">
            <div>
                <label><input type="checkbox" #allItemsToggleInput (change)="toggleSelectedAll(allItemsToggleInput.checked)" [checked]="selectedCount === totalCount" />{{infoMessage}}</label>
                <hr>
            </div>
            <ul class="overflow">
                @for (group of columnPreferences; track group) {
                    @if(group.headerName && group.children.length > 0) {
                        <li>
                            <label><input type="checkbox" [checked]="group.allSelected" #groupToggleInput (change)="toggleSelectedAllGroup(groupToggleInput.checked, group)"/>{{group.headerName}}</label>
                            <ul>
                                @for(column of group.children; track column) {
                                    <li>
                                        <label><input type="checkbox" #childInput [checked]="column.selected" (change)="toggleSelected(childInput.checked, column)"/>{{column.headerName}}</label>
                                    </li>
                                }
                            </ul>
                        </li>
                    }
                    @if (!group.headerName && group.children.length > 0) {
                        @for(column of group.children; track column) {
                            <li>
                                <label><input type="checkbox" #toggleInput [checked]="column.selected" (change)="toggleSelected(toggleInput.checked, column)"/>{{column.headerName}}</label>
                            </li>
                        }
                    }
                }
            </ul>
        </div>
    </swx-dialog-content>
    <swx-dialog-footer>
        <span>Format:</span>
        <label>
            <input type="radio" [(ngModel)]="exportFormat" [value]="'Excel'"> Excel
        </label>
        <label>
            <input type="radio" [(ngModel)]="exportFormat" [value]="'Csv'"> Csv
        </label>
        @if(!running) {
            <button [disabled]="selectedCount == 0" (click)="export()"><span class="mdi mdi-check"></span> {{'Export'|translate}}</button>
        } @else {
            <span><span class="busyIndicator"></span> {{status}}&nbsp;&nbsp;</span><button type="button" (click)="cancel()"><span class="mdi mdi-close"></span> {{'Cancel'|translate}}</button>
        }
    </swx-dialog-footer>
</swx-dialog>
