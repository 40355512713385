import { Component, ElementRef, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { MultiselectOptionComponent } from "./MultiselectOption.component";
import { TranslatePipe } from '../pipes/Translate.pipe';

@Component({
    selector: 'multiselect',
    exportAs: 'multiselect',
    styleUrl: 'Multiselect.component.scss',
    templateUrl: 'Multiselect.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiselectComponent),
            multi: true
        }
    ],
    standalone: true,
    imports: [
        TranslatePipe,
    ],
})
export class MultiselectComponent implements ControlValueAccessor {
    @Input('all') allowAll = true;
    @Input('none') noneLabel = 'None';
    @Input('max') max: number;
    @Input('width') width = 272;
    @Input('format') format: string;
    search: string;
    optionsVisible = false;
    selected = [];
    value: any;
    displayValue = null;
    destroyed = false;
    options = new Array<MultiselectOptionComponent>();
    onchange: (_: any) => void = () => { };
    ontouched: (_: any) => void = () => { };
    lastNonShiftIndex = -1;

    constructor(
        private el: ElementRef
    ) {
    }

    isOptionEnabled(option: MultiselectOptionComponent): boolean {
        if (this.selected.length === 0) return this.allowAll;
        return this.selected.indexOf(option._value) > -1;
    }

    updateSearch(search: string) {
        this.search = search;
        this.options.forEach(o => {
            o.visible = this.search == null
                || this.search === ''
                || o.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
        });
    }

    writeValue(value: any) {
        if (typeof value === 'string') {
            value = value.split(',').map(v => v.trim());
        }

        this.selected = (value || []);
        this.displayValue = null;
        this.value = this.format === 'string'
            ? this.options
                .map(o => o._value)
                .filter(o => this.selected.some(s => s === o))
                .join(',')
            : this.selected;
    }
    
    toggleOption(e: MouseEvent, option: MultiselectOptionComponent) {
        const target = e.target as HTMLInputElement;
        
        if (this.selected.length === 1 && this.selected[0] === -1) {
            this.selected.length = 0;
        }
        
        const index = this.selected.indexOf(option._value);
        
        if (index > -1) {
            if (this.selected.length === 1 && this.allowAll) {
                this.selected[0] = -1;
            } else {
                this.selected.splice(index, 1);
            }
            target.checked = false;
        } else if (this.allowAll && this.options.length === this.selected.length + 1) {
            this.selected.length = 0;
        } else {
            const optionIndex = this.options.indexOf(option);
            if (e.shiftKey && this.lastNonShiftIndex > -1) {
                const from = Math.min(this.lastNonShiftIndex, optionIndex);
                const to = Math.max(this.lastNonShiftIndex, optionIndex);
                const selectedValues = this.options
                    .slice(from, to + 1)
                    .map(o => o._value);
                this.selected = [...new Set([...this.selected, ...selectedValues])];
            }
            else {
                this.selected = [...this.selected, option._value];
                this.lastNonShiftIndex = optionIndex;
            }
            
            target.checked = true;
        }

        this.setValue(this.selected);
    }

    setValue(value: any) {
        this.writeValue(value);
        this.onchange(this.value);
        this.ontouched(this.value);
    }

    toggleAll() {
        if (this.selected.length === 0 && this.allowAll) {
            this.selected[0] = -1;
        } else {
            this.selected.length = 0;
        }

        this.writeValue(this.selected);
        this.onchange(this.value);
        this.ontouched(this.value);
    }

    getDisplayValue(): string {
        if (this.displayValue === null) {
            if (this.selected.length === 0) {
                return this.allowAll ? 'All' : this.noneLabel;
            } else if (this.selected.length === 1 && this.selected[0] === -1) {
                return this.noneLabel;
            } else {
                return this.options
                    .filter(option => this.selected.indexOf(option._value) > -1)
                    .map(option => option.name)
                    .join(', ');
            }
        }

        return this.displayValue;
    }

    displayValueClick() {
        this.optionsVisible = true;
        this.search = '';
        setTimeout(() => this.el.nativeElement.querySelector('input.display').focus());
    }

    registerOnChange(fn: (value: any) => any) {
        this.onchange = fn;
    }

    registerOnTouched(fn) {
        this.ontouched = fn;
    }
    
    _registerOption(option: MultiselectOptionComponent) {
        this.options.push(option);
    }
}
