import {Component, Inject, OnInit} from "@angular/core";
import {DIALOG_DATA} from "@angular/cdk/dialog";
import {DialogComponent, DialogContentComponent, DialogHeaderComponent} from "../dialog";
import {CdkDrag, CdkDragHandle} from "@angular/cdk/drag-drop";

@Component({
    templateUrl: 'RawDataViewDialog.component.html',
    styles: ['swx-dialog { resize: both; }'],
    standalone: true,
    imports: [
        DialogComponent, DialogHeaderComponent, DialogContentComponent, CdkDrag, CdkDragHandle
    ]
})
export class RawDataViewDialogComponent implements OnInit {
    rawData: string;
    html: string;
    title!: string;

    constructor(@Inject(DIALOG_DATA) private data: { rawData?: string, html?: string; title?: string; }) {
    }

    ngOnInit() {
        this.title = this.data.title || "Raw Data";
        this.rawData = this.data.rawData;
        this.html = this.data.html;
    }
}
