<div id="userAuthenticationsPage" class="noMargins">
    <page-title>{{('Login History'|translate)}}</page-title>
    <form #form #ngForm="ngForm" (submit)="refresh()">
        <div class="toolbar gridFilters">
            <a class="tab" (click)="switchTab('map')" [class.active]="tab == 'map'">{{'Map'}}</a>
            <a class="tab" (click)="switchTab('log')" [class.active]="tab == 'log'">{{'Log'}}</a>

            <label *ngIf="users.length > 1">
                <span class="caption">{{'User'|translate}}</span>
                <multiselect name="query.UserId" [(ngModel)]="query.UserId">
                    <multiselect-option *ngFor="let option of users|orderBy:'Name'" [value]="option.Id" [name]="option.Name"></multiselect-option>
                </multiselect>
            </label>

            <label class="inline">
                <span class="caption">{{'From'|translate}}</span>
                <input date [options]="datePickerOptions" [(ngModel)]="query.FromDate" name="FromDate"/>
            </label>
            <label class="inline">
                <span class="caption">{{'To'|translate}}</span>
                <input date [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="ToDate"/>
            </label>
            <filters name="query.Filters" [(ngModel)]="query.Filters" [columnDefs]="columnDefs"></filters>
            <label class="inline">
                <span class="caption">&nbsp;</span>
                <button type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
            </label>
            <label class="inline">
                <span class="caption">&nbsp;</span>
                <button type="button" (click)="refresh()" [disabled]="!ngForm.form.valid"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
            </label>
            <label class="inline">
                <span class="caption">&nbsp;</span>
                <button type="button" (click)="grid.export()"><span class="mdi mdi-download"></span> {{'Export'|translate}}</button>
            </label>
        </div>
    </form>
    
    <!-- Map -->
    <div [hidden]="tab === 'log'" class="userMap" #userMap></div>

    <grid [hidden]="tab === 'map'" #grid
          gridId="preferences.LoginHistory.ColumnExport"
          [query]="query"
          [resource]="api.UserAuthentication"
          [exportResource]="api.UserAuthenticationExport"
          [columnDefs]="columnDefs">
    </grid>
</div>
