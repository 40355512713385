import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router } from '@angular/router';
import 'moment-timezone';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RootScope } from '../shared/RootScope.service';
import { ApiService } from '../shared/Api.service';
import { GridComponent, ValueFormatters, SwxModule, GridModule } from 'swx.front-end-lib';
import { DiffDialogComponent } from 'swx.front-end-lib';
import { environment } from "../environments/environment";
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {Dialog} from "@angular/cdk/dialog";

@Component({
    templateUrl: 'ChangeGrid.component.html',
    standalone: true,
    imports: [
        SwxModule,
        FormsModule,
        NgIf,
        NgFor,
        GridModule,
    ],
})
export class ChangeGridComponent {
    storeKey = 'ddms.changeQuery';
    @ViewChild('grid', { static: true }) grid: GridComponent;
    search;
    rowSelection = null;
    subjectTypes = this.api.SubjectType.query();
    users = this.api.User.query();
    exportResourcePost = this.api.ChangeExport;
    datePickerOptions: any;
    setUpPromise: any;
    query: any;
    ticketTrackingBaseUrl = environment.ticketTrackingBaseUrl;
    components = {
        diffCellRenderer: DiffCellRendererComponent,
    };
    columnDefs = [
        {
            headerName: "",
            children: [
                { colId: "Date", field: "DateTime", headerName: "Date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), pinned: "left" },
                { colId: "Time", field: "DateTime", headerName: "Time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, pinned: "left", searchable: false },
                { colId: "UserName", field: "UserName", headerName: "User", width: 120 },
                { colId: "Type", field: "Type", headerName: "Type", width: 120 },
                { colId: "SubjectType", field: "SubjectType", headerName: "Section", width: 120, filterType: 'enum', source: 'SubjectType', valueFormatter: (params) => params.data?.SubjectType ? this.subjectTypes[params.data.SubjectType] : '' },
                { colId: "SubjectId", field: "SubjectId", headerName: "Id", width: 80 },
                { colId: "SubjectName", field: "SubjectName", headerName: "Name", width: 200 },
                { colId: "Comment", field: "Comment", headerName: "Comment", width: 200 },
                { colId: "TrackedTicketId", field: "TrackedTicketId", headerName: "Ticket number", width: 100, cellRenderer: params => params.data?.TrackedTicketId === null ? '' : "<a href='" + this.ticketTrackingBaseUrl + params.data?.TrackedTicketId + "' target='_blank'>" + params.data?.TrackedTicketId + "</a>" },
                { colId: "Diff", field: "Diff", headerName: "Diff", width: 40, cellRenderer: "diffCellRenderer" }
            ]
        }
    ];

    constructor(
        private router: Router,
        private $root: RootScope,
        public api: ApiService,
        private formatters: ValueFormatters,
    ) {
        this.datePickerOptions = {
            maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        };

        this.setUpPromise = Promise.all([this.subjectTypes.$promise, this.users.$promise]);

        if (window.localStorage[this.storeKey]) {
            this.query = JSON.parse(window.localStorage[this.storeKey]);
        } else {
            this.resetQuery();
        }
    }

    resetQuery() {
        this.query = {
            UserId: [],
            SubjectType: [],
            TrackedTicketId: '',
            FromDate: null,
            ToDate: null,
            Filters: []
        };
    };

    refresh() {
        window.localStorage[this.storeKey] = JSON.stringify(this.query);
        this.grid.refresh();
    }
}

@Component({
    selector: 'diff-cell',
    template: `<a href="javascript:;" *ngIf='params.data?.Diff' (click)='viewDiff(params.data)' class='mdi mdi-eye'></a>`,
    standalone: true,
    imports: [NgIf]
})
export class DiffCellRendererComponent implements ICellRendererAngularComp {
    public params: any;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private dialog: Dialog,
    ) {
    }

    agInit(params: any): void {
        this.params = params;
    }

    refresh(): boolean {
        return false;
    }

    viewDiff(row) {
        this.dialog.open(DiffDialogComponent,
            {
                data: {
                    diff: JSON.parse(row.Diff)
                },
                width: '700px',
                height: '550px',
            });
    }
}
