import {AfterViewInit, Component, Inject, OnInit, ViewChild} from "@angular/core";
import {DiffDialogComponent} from "./DiffDialog.component";
import {ExportApi, IResourceItemList} from "../ApiFactory.service";
import {IAgGridColumnDef} from "../grid/Query.model";
import {ValueFormatters} from "../ValueFormatters.service";
import {EnvironmentConfiguration} from "../EnvironmentConfiguration.model";
import { TranslatePipe } from "../pipes/Translate.pipe";
import { GridComponent } from "../grid/Grid.component";
import { FiltersComponent } from "../grid/Filters.component";
import { FormsModule } from "@angular/forms";
import {Dialog, DIALOG_DATA} from "@angular/cdk/dialog";
import {DialogComponent, DialogContentComponent, DialogHeaderComponent} from "../dialog";
import {CdkDrag, CdkDragHandle} from "@angular/cdk/drag-drop";

@Component({
    templateUrl: './ChangesDialog.component.html',
    styles: ['button { margin-right: 5px;}'],
    standalone: true,
    imports: [FormsModule, FiltersComponent, GridComponent, TranslatePipe, DialogComponent, DialogHeaderComponent, DialogContentComponent, CdkDrag, CdkDragHandle]
})
export class ChangesDialogComponent implements OnInit, AfterViewInit {
    @ViewChild('grid') grid;
    title!: string;
    query: any;
    changeAlertsQuery: any;
    ticketTrackingBaseUrl = this.environment.ticketTrackingBaseUrl;
    columnDefs: IAgGridColumnDef[] = [
        {colId: 'Date', field: 'DateTime', headerName: 'Date', width: 90, valueFormatter: this.formatters.utcDateFormatter(), filterType: 'date'},
        {colId: 'Time', field: 'DateTime', headerName: 'Time', width: 70, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, searchable: false },
        {colId: 'UserName', field: 'UserName', headerName: 'User', width: 100},
        {colId: 'Type', field: 'Type', headerName: 'Type', width: 90},
        {colId: 'Ticket', field: 'TrackedTicketId', headerName: 'Ticket', width: 90, cellTemplate: '<a *ngIf="params.data?.TrackedTicketId" href="{{parent.ticketTrackingBaseUrl + params.data?.TrackedTicketId}}" target="_blank">{{params.data?.TrackedTicketId}}</a>'},
        {colId: 'Comment', field: 'Comment', headerName: 'Comment', width: 200},
        {colId: 'Diff', field: 'Diff', headerName: 'Diff', width: 60, cellTemplate: '<a *ngIf="params.data?.Diff" (click)="parent.viewDiff(params.data?.Diff)" class="mdi mdi-eye"></a>'}
    ];
    resource = this.api.Change;
    exportResource = this.api.ChangeExport;

    constructor(
        @Inject(DIALOG_DATA) private data: any,
        private api: ExportApi,
        private dialog: Dialog,
        private formatters: ValueFormatters,
        private environment: EnvironmentConfiguration,
    ) {
    }

    ngOnInit(): void {
        this.title = this.data.title;
        this.query = this.data.query;
        this.changeAlertsQuery = this.data.changeAlertsQuery;
        
        this.changeAlerts = this.api.ChangeAlert.query(this.changeAlertsQuery);
        this.changeAlerts.$promise.then(() => {
            this.saving = false;
        });
    }

    ngAfterViewInit(): void {
        this.grid.gridReadyPromise.then(() => {
            this.grid.refresh();
        });
    }

    changes: IResourceItemList<any>;
    changeAlerts: IResourceItemList<any>;
    saving = true;

    toggleChangeAlert() {
        this.saving = true;
        if (this.changeAlerts.length === 0) {
            const changeAlert = this.api.ChangeAlert.create({
                UserId: this.changeAlertsQuery.UserId,
                SubjectType: this.changeAlertsQuery.SubjectType[0],
                SubjectId: this.changeAlertsQuery.SubjectId,
            });
            changeAlert.$save().then(() => {
                this.changeAlerts = this.api.ChangeAlert.query(this.changeAlertsQuery);
                this.changeAlerts.$promise.then(() => {
                    this.saving = false;
                });
            });
        } else {
            this.api.ChangeAlert.delete(this.changeAlertsQuery).then(() => {
                this.changeAlerts = this.api.ChangeAlert.query(this.changeAlertsQuery);
                this.changeAlerts.$promise.then(() => {
                    this.saving = false;
                });
            });
        }
    }

    viewDiff(diff) {
        this.dialog.open(DiffDialogComponent, {
            width: '700px',
            height: '550px',
            data: {
                diff: JSON.parse(diff)
            }
        })
    }
}
