import {Component} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {DialogComponent, DialogContentComponent, DialogHeaderComponent} from "../dialog";
import {DialogRef} from "@angular/cdk/dialog";
import {TranslatePipe} from "../pipes/Translate.pipe";

@Component({
    templateUrl: './TicketTrackingCommentDialog.component.html',
    standalone: true,
    imports: [FormsModule, DialogComponent, DialogHeaderComponent, DialogContentComponent, TranslatePipe]
})
export class TicketTrackingCommentDialogComponent {
    ticketNumber;
    comments;
    
    constructor(private dialogRef: DialogRef) {
    }
    
    save() {
        this.dialogRef.close({
            TicketNumber: this.ticketNumber,
            Comment: this.comments
        });
    }

    cancel() {
        this.dialogRef.close();
    }
}

export interface TicketTrackingCommentInfo {
    TicketNumber;
    Comment;
}
