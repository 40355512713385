import {Component, Input, ViewChild} from '@angular/core';
import { SortableComponent, SwxModule } from "swx.front-end-lib";
import { ControlContainer, NgModelGroup, FormsModule } from "@angular/forms";
import {Field, FieldGroup} from "swx.front-end-lib/lib/grid/Query.model";
import { HasPermissionPipe } from '../shared/HasPermission.pipe';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'service-monitoring-condition-list',
    templateUrl: 'ServiceMonitoringConditionList.component.html',
    styleUrls: ['./ServiceMonitoringConditionList.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
    standalone: true,
    imports: [SwxModule, NgFor, FormsModule, NgIf, HasPermissionPipe]
})
export class ServiceMonitoringConditionListComponent {
    @Input() conditions;
    @Input() parent;
    @Input() parentList: ServiceMonitoringConditionListComponent;
    @Input() ngForm;
    @ViewChild('sortable', { static: true }) sortable: SortableComponent;
    @Input() availableFields: Map<string, Field>;
    @Input() fieldGroups: FieldGroup[];
    
    addCondition(parent, type: string) {
        var maxOrder = Math.max.apply(null, this.conditions.filter(c => c.ParentConditionId == this.parent?.Id)
            .map(h => h.Order));
        this.conditions.push({
            Id: ServiceMonitoringConditionListComponent.uuidv4(),
            ParentConditionId: this.parent?.Id,
            Order: Math.max(1, maxOrder + 1),
            ConditionType: type,
            MinimumDurationMinutes: 1,
        });
        this.ngForm.form.markAsDirty();
    }

    //https://stackoverflow.com/a/2117523/530630
    static uuidv4() {
        return `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, c =>
            (parseInt(c) ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> parseInt(c) / 4).toString(16)
        );
    }
    
    childConditions() {
        return this.conditions?.filter(c => c.ParentConditionId == this.parent?.Id) ?? [];
    }

    removeServiceMonitoringCondition(parent, condition) {
        this.conditions.splice(this.conditions.indexOf(condition), 1);
        this.ngForm.form.markAsDirty();
    }
}
