import { Component, Input } from "@angular/core";
import { SwxModule } from "swx.front-end-lib";
import { NgIf, NgFor, DecimalPipe } from "@angular/common";

@Component({
    selector: 'dashboard-service',
    templateUrl: './DashboardService.component.html',
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        DecimalPipe,
        SwxModule,
    ],
})
export class DashboardServiceComponent {
    @Input() name;
    @Input() serviceStatusHistory;
    Math = Math;
}
