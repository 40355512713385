<swx-dialog cdkDrag>
    <swx-dialog-header>Select Columns</swx-dialog-header>
    <swx-dialog-content>
        <div class="column-selection-dialog-content">
            <div>
                <label><input type="checkbox" #allItemsToggleInput (change)="toggleSelectedAll(allItemsToggleInput.checked)" [checked]="selectedCount === totalCount" />{{infoMessage}}</label>
                <hr>
            </div>
            <div class="overflow">
                <ul>
                    @for(group of columnPreferences; track group) {
                        @if(group.headerName && group.children.length > 0) {
                            <li>
                                <label><input type="checkbox" #groupToggleInput [checked]="group.allSelected" (change)="toggleSelectedAllGroup(groupToggleInput.checked, group)"/>{{group.headerName}}</label>
                                <ul>
                                    @for(column of group.children; track column) {
                                        <li>
                                            <label><input type="checkbox" #childInput [checked]="column.selected || column.alwaysSelected" [disabled]="column.alwaysSelected" (change)="toggleSelected(childInput.checked, column)"/>{{column.headerName || column.colId}}</label>
                                        </li>
                                    }
                                </ul>
                            </li>
                        }
                        @if(!group.headerName && group.children.length > 0) {
                            @for(column of group.children; track column) {
                                <li>
                                    <label><input type="checkbox" #toggleInput [checked]="column.selected || column.alwaysSelected" [disabled]="column.alwaysSelected" (change)="toggleSelected(toggleInput.checked, column)"/>{{column.headerName || column.colId}}</label>
                                </li>
                            }
                        }
                    }
                </ul>
            </div>
        </div>
    </swx-dialog-content>
    <swx-dialog-footer>
        <button [disabled]="selectedCount == 0" (click)="confirmSelection()"><span class="mdi mdi-check"></span> {{'Save'|translate}}</button>
        <button type="button" (click)="close()"><span class="mdi mdi-close"></span> {{'Cancel'|translate}}</button>
    </swx-dialog-footer>
</swx-dialog>
