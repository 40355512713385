<div id="serviceStatusPage" class="noMargins">
    <page-title>{{('Service status'|translate)}}</page-title>
    <form #form="ngForm" (submit)="refresh()" class="toolbar gridFilters">
        <a class="tab" (click)="switchTab('history')" [class.active]="tab == 'history'">{{'History'|translate}}</a>
        <a class="tab" (click)="switchTab('graphs')" [class.active]="tab == 'graphs'">{{'Graphs'|translate}}</a>
        <label class="inline">
            <span class="caption">{{'From'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.FromDate" name="FromDate" />
        </label>
        <label class="inline">
            <span class="caption">{{'To'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="ToDate" />
        </label>

        <filters *ngIf="tab == 'history'" [(ngModel)]="query.Filters" [columnDefs]="historyColumnDefs" name="query.Filters"></filters>
        <button *ngIf="tab == 'history'" type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
        <button *ngIf="tab != 'graphs'" type="button" (click)="refresh()" [disabled]="!form.valid"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
        <button *ngIf="tab == 'history'" type="button" (click)="export()" [disabled]="!form.valid"><span class="mdi mdi-download"></span>{{'Export'|translate}}</button>
        <button *ngIf="tab == 'history'" type="button" (click)="historyGrid.selectColumns()" [disabled]="!form.valid"><span class="mdi mdi-table-edit"></span>{{'Columns'|translate}}</button>
    </form>
    
    <div [hidden]="tab !== 'graphs'" class="graphs" #graphs></div>

    <grid [hidden]="tab !== 'history'"
          #historyGrid
          gridId="serviceStatusHistory"
          [query]="query"
          [resource]="api.ServiceStatus"
          [exportResource]="api.ServiceStatusExport"
          [columnDefs]="historyColumnDefs">
    </grid>
</div>
