import {Component, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import { ApiService } from "../shared/Api.service";
import { GridComponent, DialogService, IAgGridColGroupDef, IAgGridColumnDef, ValueFormatters, SwxModule, GridModule } from "swx.front-end-lib";
import { RootScope } from "../shared/RootScope.service";
import moment from 'moment';
import {ServiceStatusColumnDefinitionsService} from "../serviceStatus/ServiceStatusColumnDefinitions.service";
import {RawDataDialogComponent} from "../shared/RawDataDialog.component";
import {environment} from "../environments/environment";
import { NgFor } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: './ServiceMonitoringAlertList.component.html',
    standalone: true,
    imports: [
        SwxModule,
        FormsModule,
        NgFor,
        GridModule,
    ],
})
export class ServiceMonitoringAlertListComponent implements OnInit {
    query: any;
    storeKey = 'serviceMonitoringAlertQuery';
    datePickerOptions: DateTimePickerOptions;
    @ViewChild('grid', { static: true }) grid: GridComponent;
    serviceMonitoringProfiles;
    ticketTrackingBaseUrl = environment.ticketTrackingBaseUrl;
    serviceMonitoringAlertColumns: IAgGridColGroupDef = {
        headerName: "",
        children: [
            { colId: "Date", field: "ServiceMonitoringAlert.DateTime", headerName: "Date", width: 90, valueFormatter: c => c.value ? moment.utc(c.value).format(this.$root.dateFormat) : "", pinned: "left" },
            { colId: "Time", field: "ServiceMonitoringAlert.DateTime", headerName: "Time", width: 60, valueFormatter: c => c.value ? moment.utc(c.value).format(this.$root.timeFormat) : "", excelIgnore: true, pinned: "left" },
            { colId: "ServiceMonitoringAlert.Id", field: "ServiceMonitoringAlert.Id", headerName: "#", width: 70, filterType: "integer", pinned: 'left' },
            { colId: "ServiceMonitoringProfileName", field: "ServiceMonitoringProfileName", headerName: "Profile", width: 150 },
            { colId: "ServiceMonitoringActionType", field: "ServiceMonitoringActionType", headerName: "Type", width: 100, filterType: 'enum', source: 'ServiceMonitoringActionType' },
            { colId: "ServiceMonitoringAlertAction.Recipient", field: "ServiceMonitoringAlertAction.Recipient", headerName: "Recipients", width: 300 },
            { colId: "ServiceMonitoringAlertAction.Subject", field: "ServiceMonitoringAlertAction.Subject", headerName: "Subject", width: 350 },
            { colId: 'Ticket', field: 'ServiceMonitoringAlert.TicketTrackingId', headerName: 'Ticket', width: 90, cellTemplate: '<a *ngIf="params.data?.ServiceMonitoringAlert.TicketTrackingId" href="{{parent.ticketTrackingBaseUrl + params.data?.ServiceMonitoringAlert.TicketTrackingId}}" target="_blank">{{params.data?.ServiceMonitoringAlert.TicketTrackingId}}</a>' },
            { colId: "ServiceMonitoringAlertAction.Content", field: "ServiceMonitoringAlertAction.Content", headerName: "Content", width: 80, cellRenderer: c => {
                    var container = document.createElement('div');
                    if (c.value) {
                        container.innerHTML = '<a href onclick="event.preventDefault();" class="mdi mdi-eye"></a>';
                        container.querySelector('a').addEventListener('click', () => {
                            var bodyIndex = c.value?.lastIndexOf("<body>");
                            const body = bodyIndex > -1 ? (c.value?.substring(bodyIndex + 6, c.value.lastIndexOf("</body>"))) : c.value;

                            this.dialogService.show(this.viewContainerRef, RawDataDialogComponent,
                                {
                                    html: body,
                                },
                                {
                                    title: 'Monitoring alert',
                                    width: '80%',
                                    height: 800,
                                    modal: true,
                                });
                        });
                    }
                    return container;
                } },
        ]
    };
    columnDefs: (IAgGridColumnDef | IAgGridColGroupDef)[] = [
        this.serviceMonitoringAlertColumns,
        this.serviceStatusColumns.serviceMonitoringColumns,
        this.serviceStatusColumns.dataCollectorColumns,
        this.serviceStatusColumns.communicatorColumns,
        this.serviceStatusColumns.portalColumns,
        this.serviceStatusColumns.clientColumns,
        this.serviceStatusColumns.configurationColumns,
        this.serviceStatusColumns.ddmsColumns,
        this.serviceStatusColumns.serviceProviderColumns,
        this.serviceStatusColumns.dataWarehouseSyncColumns,
        this.serviceStatusColumns.queueDepthColumns,
    ];

    constructor(
        public $root: RootScope,
        private viewContainerRef: ViewContainerRef,
        public api: ApiService,
        private dialogService: DialogService,
        private serviceStatusColumns: ServiceStatusColumnDefinitionsService,
        private formatters: ValueFormatters,
    ) {
    }

    ngOnInit(): void {
        this.datePickerOptions = {
            maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        };

        if (window.localStorage[this.storeKey]) {
            this.query = JSON.parse(window.localStorage[this.storeKey]);
        } else {
            this.resetQuery();
        }

        this.api.ServiceMonitoringProfile.query().$promise.then(monitoringProfiles => {
            this.serviceMonitoringProfiles = monitoringProfiles;
        });
    }

    resetQuery() {
        this.query = {
            Filters: [],
            FromDate: null,
            ToDate: null,
        };
    }

    refresh() {
        window.localStorage[this.storeKey] = JSON.stringify(this.query);
        this.grid.refresh();
    }
}
