import {Injectable} from "@angular/core";
import {AuthenticationService} from "../auth/Authentication.service";
import {ChangesDialogComponent} from "./ChangesDialog.component";
import {Dialog} from "@angular/cdk/dialog";

@Injectable({ providedIn: 'root'})
export class ChangesService {
    constructor(
        private dialog: Dialog,
        private authentication: AuthenticationService,
    ) {
    }

    show(subjectInfo) {
        this.authentication.getCurrentUser()
            .then(user => {
                this.dialog.open(ChangesDialogComponent,
                    {
                        data: {
                            title: 'Changes',
                            query: subjectInfo,
                            changeAlertsQuery: {
                                UserId: user.Id,
                                SubjectType: subjectInfo.SubjectType,
                                SubjectId: subjectInfo.SubjectId
                            }
                        },
                        width: '750px',
                        height: '550px',
                    })
            });
    }
}
